import axios from '@/axios'

export default {
  namespaced: true,
  state: {
    loading: false,
    bookings: [],
  },
  getters: {
    loading(state) {
      return state.loading
    },
    bookings(state) {
      return state.bookings
    },
    currentBookings(state) {
      if (!state.bookings.length) return []
      return state.bookings
        .filter(booking => {
          if (!booking.confirmed) return false
          const checkoutDate = new Date(booking.checkout)
          const today = new Date()
          return today <= checkoutDate
        })
        .sort((a, b) => {
          const aCheckin = new Date(a.checkin)
          const bCheckin = new Date(b.checkin)
          if (aCheckin > bCheckin) return 1
          return bCheckin > aCheckin ? -1 : 0
        })
    },
    pastBookings(state) {
      if (!state.bookings.length) return []
      return state.bookings
        .filter(booking => {
          if (booking.completed || booking.cancelled) return true
          if (booking.confirmed) {
            const checkoutDate = new Date(booking.checkout)
            const today = new Date()
            return today > checkoutDate
          }
          return false
        })
        .sort((a, b) => {
          const aCheckin = new Date(a.checkin)
          const bCheckin = new Date(b.checkin)
          if (aCheckin < bCheckin) return 1
          return bCheckin < aCheckin ? -1 : 0
        })
    },
  },
  mutations: {
    RESET(state) {
      state.loading = false
      state.bookings = []
    },
    SET_LOADING(state, payload) {
      state.loading = payload
    },
    SET_BOOKINGS(state, payload) {
      state.bookings = payload
    },
    UPDATE_BOOKING(state, booking) {
      if (state.bookings.length > 0) {
        const bookingIndex = state.bookings.findIndex(
          b => b.uuid === booking.uuid,
        )
        state.bookings.splice(bookingIndex, 1, booking)
      }
    },
  },
  actions: {
    reset({ commit }) {
      commit('RESET')
    },
    fetchBookings({ state, commit }, payload = {}) {
      commit('SET_LOADING', true)

      let url = '/bookings?'
      if ('pagination' in payload) url += `&pagination=${payload.pagination}`
      if ('itemsPerPage' in payload) url += `&itemsPerPage=${payload.itemsPerPage || state.itemsPerPage}`
      if ('page' in payload) url += `&page=${payload.page}`

      return new Promise((resolve, reject) => {
        axios
          .get(url)
          .then(response => {
            if (response.status === 200) {
              commit('SET_BOOKINGS', response.data['hydra:member'])
              resolve(response)
            } else {
              reject(new Error({
                status: response.status,
                message: 'Error while fetching all the bookings',
              }))
            }
          })
          .catch(error => {
            reject(error.message)
            // TODO: log error in Sentry
          })
          .finally(() => commit('SET_LOADING', false))
      })
    },
  },
}
