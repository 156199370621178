export default [
  //    - Airport transfer
  {
    path: "/services/airport-transfer",
    name: "foravila-services-airport-transfer",
    component: () =>
      import("@/views/foravila/services/airport-transfer/AirportTransfer.vue")
  },
  //    - Taxi
  // {
  //   path: '/services/taxi',
  //   name: 'foravila-services-taxi',
  //   component: () => import('@/views/foravila/services/TaxiService.vue'),
  //   meta: {
  //     pageTitle: 'Taxi',
  //     breadcrumb: [
  //       { text: 'Home' },
  //       { text: 'Servcies' },
  //       { text: 'Taxi', active: true },
  //     ],
  //   },
  // },
  //    - Charter
  // {
  //   path: '/services/charter',
  //   name: 'foravila-services-charter',
  //   component: () => import('@/views/foravila/services/CharterService.vue'),
  //   meta: {
  //     pageTitle: 'Charter',
  //     breadcrumb: [
  //       { text: 'Home' },
  //       { text: 'Servcies' },
  //       { text: 'Charter', active: true },
  //     ],
  //   },
  // },
  //   - Food service
  {
    path: "/services/food",
    name: "foravila-services-food",
    component: () => import("@/views/foravila/services/food/FoodService.vue")
  },
  //    - Renta a car
  {
    path: "/services/car-rental",
    name: "foravila-services-car-rental",
    component: () =>
      import("@/views/foravila/services/car-rental/CarRentalService.vue")
  }
];
