import axios from "@/axios";

export default {
  namespaced: true,
  state: {
    loadingGlobalServcies: false,
    globalServices: []
  },
  getters: {
    loadingGlobalServcies(state) {
      return state.loadingGlobalServcies;
    },
    globalServices(state) {
      return state.globalServices;
    }
  },
  mutations: {
    SET_GLOBAL_SERVICES(state, services) {
      state.globalServices = services;
    }
  },
  actions: {
    requestAirportTransfer(context, data) {
      return new Promise((resolve, reject) => {
        if (!data) reject();

        axios
          .post("/airport_transfers", data)
          .then(response => {
            resolve(response.data);
          })
          .catch(error => {
            // TODO: log the error with Sentry
            reject(error);
          });
      });
    },
    fetchGlobalServices({ commit }, payload = {}) {
      return new Promise((resolve, reject) => {
        try {
          let url = "/global_services?";

          if ("pagination" in payload) {
            url += `pagination=${payload.pagination}`;
          }
          axios
            .get(url)
            .then(response => {
              commit("SET_GLOBAL_SERVICES", response.data["hydra:member"]);
              resolve(response);
            })
            .catch(error => {
              reject(error.response);
              // TODO: Log error in Sentry
            });
        } catch (error) {
          reject(new Error(error.message));
        }
      });
    }
  }
};
