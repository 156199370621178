import Vue from "vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import countries from "@/utils/countries";
import { TPV_BASE_LINK } from "@appConfig";
import { formatCurrency } from "./formatters";
import { UNCALCULABLE_FREQUENCIES } from "./consts";

/** **************** */
/** **************** */
/* PRIVATE FUNCTIONS */
/** **************** */
/** **************** */

function simplifyLocale(locale) {
  if (!locale) return null;
  return locale.split("-")[0].toLowerCase();
}

/** ****** */
/** ****** */
/* EXPORTS */
/** ****** */
/** ****** */

export const isEmpty = data => {
  if (typeof data === "string") {
    return data.trim().length === 0;
  }
  if (typeof data === "number" || typeof data === "boolean") {
    return false;
  }
  if (typeof data === "undefined" || data === null) {
    return true;
  }
  if (typeof data.length !== "undefined") {
    return data.length === 0;
  }

  // let count = 0
  // for (const i in data) {
  //   if (data.hasOwnProperty(i)) {
  //     count++
  //   }
  // }
  // return count == 0
  return false;
};

export const notifyError = (title, text) => {
  Vue.$toast(
    {
      component: ToastificationContent,
      props: {
        title,
        icon: "AlertTriangleIcon",
        text,
        variant: "danger"
      }
    },
    {
      timeout: 6000
    }
  );
};

export const notifySuccess = (title, text) => {
  Vue.$toast(
    {
      component: ToastificationContent,
      props: {
        title,
        icon: "CheckCircleIcon",
        text,
        variant: "success"
      }
    },
    {
      timeout: 6000
    }
  );
};

export const getCountryName = (countryCode, locale = "ca-ES") => {
  if (!countryCode) return null;

  const country = countries.find(c => c.code === countryCode);
  if (!country) return countryCode;

  switch (simplifyLocale(locale)) {
    case "ca":
      return country.name_ca;
    case "es":
      return country.name_es;
    case "en":
      return country.name_en;
    case "de":
      return country.name_de;
    default:
      return countryCode;
  }
};

export const getCountryCodeFromDialCode = dialCode => {
  if (!dialCode) return null;
  const country = countries.find(c => c.dial_code === dialCode);
  return country?.code || null;
};

export const getAddressTypeName = addressTypeCode => {
  if (!addressTypeCode) return null;
  switch (addressTypeCode) {
    case "STREET":
      return "Carrer";
    case "AVENUE":
      return "Avinguda";
    case "PATH":
    case "PATHWAY":
      return "Camí";
    case "ROAD":
      return "Carretera";
    case "DISSEMINATED":
      return "Disseminat";
    case "WAY":
      return "Via";
    case "FREEWAY":
      return "Autovia";
    case "SQUARE":
      return "Plaça";
    case "BOULEVARD":
      return "Boulevard";
    case "COURT":
      return "Pati";
    case "PASSAGE":
      return "Passatge";
    case "PROMENADE":
      return "Passeig";
    case "RAMBLA":
      return "Rambla";
    case "OTHER":
      return "Altres";
    default:
      return addressTypeCode;
  }
};

export const getFullAddress = (
  location,
  locale = "ca-ES",
  city = true,
  region = true,
  country = true
) => {
  if (!location) return null;

  const address = [];

  if (
    (location.addressType && location.addressType !== "DISSEMINATED") ||
    location.addressName
  ) {
    const addressName = [];
    if (!isEmpty(location.addressType)) {
      addressName.push(getAddressTypeName(location.addressType, locale));
    }
    if (!isEmpty(location.addressName)) {
      addressName.push(location.addressName);
    }
    if (!isEmpty(location.addressNumber)) {
      addressName.push(location.addressNumber);
    }
    if (addressName.length > 0) address.push(addressName.join(" "));
  }

  switch (simplifyLocale(locale)) {
    case "de":
      if (!isEmpty(location.addressBlock)) {
        address.push(`Block ${location.addressBlock}`);
      }
      if (!isEmpty(location.addressPortal)) {
        address.push(`Eingang ${location.addressPortal}`);
      }
      if (!isEmpty(location.addressStairs)) {
        address.push(`Treppenhaus ${location.addressStairs}`);
      }
      if (!isEmpty(location.addressFloor)) {
        address.push(`Stock ${location.addressFloor}`);
      }
      if (!isEmpty(location.addressDoor)) {
        address.push(`Tür ${location.addressDoor}`);
      }
      if (!isEmpty(location.addressPolygon)) {
        address.push(`Grundstück ${location.addressPolygon}`);
      }
      if (!isEmpty(location.addressPlot)) {
        address.push(`Flurstück ${location.addressPlot}`);
      }
      break;
    case "en":
      if (!isEmpty(location.addressBlock)) {
        address.push(`Block ${location.addressBlock}`);
      }
      if (!isEmpty(location.addressPortal)) {
        address.push(`Entrance ${location.addressPortal}`);
      }
      if (!isEmpty(location.addressStairs)) {
        address.push(`Staircase ${location.addressStairs}`);
      }
      if (!isEmpty(location.addressFloor)) {
        address.push(`Floor ${location.addressFloor}`);
      }
      if (!isEmpty(location.addressDoor)) {
        address.push(`Door ${location.addressDoor}`);
      }
      if (!isEmpty(location.addressPolygon)) {
        address.push(`Polygon ${location.addressPolygon}`);
      }
      if (!isEmpty(location.addressPlot)) {
        address.push(`Plot ${location.addressPlot}`);
      }
      break;
    case "ca":
      if (!isEmpty(location.addressBlock)) {
        address.push(`Bloc ${location.addressBlock}`);
      }
      if (!isEmpty(location.addressPortal)) {
        address.push(`Portal ${location.addressPortal}`);
      }
      if (!isEmpty(location.addressStairs)) {
        address.push(`Escala ${location.addressStairs}`);
      }
      if (!isEmpty(location.addressFloor)) {
        address.push(`Pis ${location.addressFloor}`);
      }
      if (!isEmpty(location.addressDoor)) {
        address.push(`Porta ${location.addressDoor}`);
      }
      if (!isEmpty(location.addressPolygon)) {
        address.push(`Polígon ${location.addressPolygon}`);
      }
      if (!isEmpty(location.addressPlot)) {
        address.push(`Parcel·la ${location.addressPlot}`);
      }
      break;
    case "es":
      if (!isEmpty(location.addressBlock)) {
        address.push(`Bloque ${location.addressBlock}`);
      }
      if (!isEmpty(location.addressPortal)) {
        address.push(`Portal ${location.addressPortal}`);
      }
      if (!isEmpty(location.addressStairs)) {
        address.push(`Escalera ${location.addressStairs}`);
      }
      if (!isEmpty(location.addressFloor)) {
        address.push(`Piso ${location.addressFloor}`);
      }
      if (!isEmpty(location.addressDoor)) {
        address.push(`Puerta ${location.addressDoor}`);
      }
      if (!isEmpty(location.addressPolygon)) {
        address.push(`Polígono ${location.addressPolygon}`);
      }
      if (!isEmpty(location.addressPlot)) {
        address.push(`Parcela ${location.addressPlot}`);
      }
      break;
    default:
      if (!isEmpty(location.addressBlock)) {
        address.push(`Block ${location.addressBlock}`);
      }
      if (!isEmpty(location.addressPortal)) {
        address.push(`Portal ${location.addressPortal}`);
      }
      if (!isEmpty(location.addressStairs)) {
        address.push(`Stairs ${location.addressStairs}`);
      }
      if (!isEmpty(location.addressFloor)) {
        address.push(`Floor ${location.addressFloor}`);
      }
      if (!isEmpty(location.addressDoor)) {
        address.push(`Door ${location.addressDoor}`);
      }
      if (!isEmpty(location.addressPolygon)) {
        address.push(`Polygon ${location.addressPolygon}`);
      }
      if (!isEmpty(location.addressPlot)) {
        address.push(`Plot ${location.addressPlot}`);
      }
      break;
  }

  if (city && (location.zip || location.city)) {
    const addressCity = [];
    if (!isEmpty(location.zip)) addressCity.push(location.zip);
    if (!isEmpty(location.city)) addressCity.push(location.city);
    if (addressCity.length > 0) address.push(addressCity.join(" "));
  }

  if (region && !isEmpty(location.region)) {
    address.push(location.region);
  }

  if (country && !isEmpty(location.country)) {
    address.push(getCountryName(location.country, locale));
  }

  return address ? address.join(", ") : null;
};

export const getOtaName = otaCode => {
  if (!otaCode) return null;
  switch (otaCode.toUpperCase()) {
    case "101HOTELS":
      return "101Hotels.com";
    case "AGODA":
      return "Agoda";
    case "AIRBNB":
      return "Airbnb";
    case "ATRAVEO":
      return "Atraveo";
    case "BOOKING":
      return "Booking.com";
    case "BUNGALOW":
      return "Bungalow";
    case "CASAMUNDO":
      return "Casamundo";
    case "EDOMIZIL":
      return "E-Domizil";
    case "EDREAMS":
      return "eDreams";
    case "EXPEDIA":
      return "Expedia";
    case "FORAVILA":
      return "Foravila Rentals";
    case "HOLIDU":
      return "Holidu";
    case "HOMEAWAY":
      return "HomeAway";
    case "HOMETOGO":
      return "HomeToGo";
    case "HOTELBEDS":
      return "Hotelbeds";
    case "HOUSETRIP":
      return "HouseTrip";
    case "LOCASUN":
      return "Locasun";
    case "MUCHOSOL":
      return "Muchosol";
    case "TRAUM":
      return "Traum-Ferienwohnungen";
    case "VACASOL":
      return "Vacasol";
    case "VILLAPARTNER":
      return "Villapartner";
    case "VRBO":
      return "Vrbo";
    case "WIMDU":
      return "Wimdu";
    default:
      return "Portal";
  }
};

export const getPaymentFrequencyUnit = (code, locale = "ca-ES") => {
  if (!code) {
    return null;
  }

  switch (simplifyLocale(locale)) {
    case "de":
      switch (code.toUpperCase()) {
        case "HOUR":
          return "/Stunde";
        case "DAY":
          return "/Tag";
        case "WEEK":
          return "/Woche";
        case "LITER":
          return "/Liter";
        case "KW":
          return "/KWh";
        case "ONCE":
          return null;
        default:
          return code.toUpperCase();
      }
    case "en":
      switch (code.toUpperCase()) {
        case "HOUR":
          return "/hour";
        case "DAY":
          return "/day";
        case "WEEK":
          return "/week";
        case "LITER":
          return "/liter";
        case "KW":
          return "/KWh";
        case "ONCE":
          return null;
        default:
          return code.toUpperCase();
      }
    case "ca":
      switch (code.toUpperCase()) {
        case "HOUR":
          return "/hora";
        case "DAY":
          return "/dia";
        case "WEEK":
          return "/setmana";
        case "LITER":
          return "/litre";
        case "KW":
          return "/KWh";
        case "ONCE":
          return null;
        default:
          return code.toUpperCase();
      }
    case "es":
      switch (code.toUpperCase()) {
        case "HOUR":
          return "/hora";
        case "DAY":
          return "/día";
        case "WEEK":
          return "/semana";
        case "LITER":
          return "/litro";
        case "KW":
          return "/KWh";
        case "ONCE":
          return null;
        default:
          return code.toUpperCase();
      }
    default:
      switch (code.toUpperCase()) {
        case "HOUR":
          return "/hour";
        case "DAY":
          return "/day";
        case "WEEK":
          return "/week";
        case "LITER":
          return "/liter";
        case "KW":
          return "/KWh";
        case "ONCE":
          return null;
        default:
          return code.toUpperCase();
      }
  }
};

export const getPaymentTimeName = (code, locale = "ca-ES") => {
  if (!code) {
    return null;
  }

  switch (simplifyLocale(locale)) {
    case "de":
      switch (code.toUpperCase()) {
        case "INITIAL_PAYMENT":
          return "Anzahlung";
        case "FINAL_PAYMENT":
          return "Restzahlung";
        case "BEFORE_CHECK_IN":
          return "Vor dem Check-in";
        case "CHECK_IN":
          return "Beim Check-in";
        case "CHECK_OUT":
          return "Beim Check-out";
        case "PROVIDER":
          return "Zum Anbieter";
        default:
          return code.toUpperCase();
      }
    case "en":
      switch (code.toUpperCase()) {
        case "INITIAL_PAYMENT":
          return "Initial payment";
        case "FINAL_PAYMENT":
          return "Final payment";
        case "BEFORE_CHECK_IN":
          return "Before check-in";
        case "CHECK_IN":
          return "During check-in";
        case "CHECK_OUT":
          return "During check out";
        case "PROVIDER":
          return "To the provider";
        default:
          return code.toUpperCase();
      }
    case "ca":
      switch (code.toUpperCase()) {
        case "INITIAL_PAYMENT":
          return "Pagament inicial";
        case "FINAL_PAYMENT":
          return "Pagament final";
        case "BEFORE_CHECK_IN":
          return "Abans de l'entrada";
        case "CHECK_IN":
          return "A l'entrada";
        case "CHECK_OUT":
          return "A la sortida";
        case "PROVIDER":
          return "Al proveïdor";
        default:
          return code.toUpperCase();
      }
    case "es":
      switch (code.toUpperCase()) {
        case "INITIAL_PAYMENT":
          return "Pago inicial";
        case "FINAL_PAYMENT":
          return "Pago final";
        case "BEFORE_CHECK_IN":
          return "Antes de la entrada";
        case "CHECK_IN":
          return "A la entrada";
        case "CHECK_OUT":
          return "A la salida";
        case "PROVIDER":
          return "Al proveedor";
        default:
          return code.toUpperCase();
      }
    default:
      switch (code.toUpperCase()) {
        case "INITIAL_PAYMENT":
          return "Initial payment";
        case "FINAL_PAYMENT":
          return "Final payment";
        case "BEFORE_CHECK_IN":
          return "Before check-in";
        case "CHECK_IN":
          return "During check-in";
        case "CHECK_OUT":
          return "During check out";
        case "PROVIDER":
          return "To the provider";
        default:
          return code.toUpperCase();
      }
  }
};

export const getBookingStatusName = (code, locale = "ca-ES") => {
  if (!code) {
    return null;
  }

  switch (simplifyLocale(locale)) {
    case "de":
      switch (code.toUpperCase()) {
        case "REQUESTED":
          return "Angefordert";
        case "CONFIRMED":
          return "Bestätigt";
        case "CANCELED":
        case "CANCELLED":
          return "Abgesagt";
        case "COMPLETED":
          return "Vollendet";
        default:
          return code.toUpperCase();
      }
    case "en":
      switch (code.toUpperCase()) {
        case "REQUESTED":
          return "Requested";
        case "CONFIRMED":
          return "Confirmed";
        case "CANCELED":
        case "CANCELLED":
          return "Cancelled";
        case "COMPLETED":
          return "Completed";
        default:
          return code.toUpperCase();
      }
    case "ca":
      switch (code.toUpperCase()) {
        case "REQUESTED":
          return "Sol·licitada";
        case "CONFIRMED":
          return "Confirmada";
        case "CANCELED":
        case "CANCELLED":
          return "Cancel·lada";
        case "COMPLETED":
          return "Completada";
        default:
          return code.toUpperCase();
      }
    case "es":
      switch (code.toUpperCase()) {
        case "REQUESTED":
          return "Solicitada";
        case "CONFIRMED":
          return "Confirmada";
        case "CANCELED":
        case "CANCELLED":
          return "Cancelada";
        case "COMPLETED":
          return "Completada";
        default:
          return code.toUpperCase();
      }
    default:
      switch (code.toUpperCase()) {
        case "REQUESTED":
          return "Requested";
        case "CONFIRMED":
          return "Confirmed";
        case "CANCELED":
        case "CANCELLED":
          return "Cancelled";
        case "COMPLETED":
          return "Completed";
        default:
          return code.toUpperCase();
      }
  }
};

export const getBookingStatusColor = code => {
  if (!code) {
    return null;
  }

  switch (code.toUpperCase()) {
    case "COMPLETED":
      return "primary";
    case "REQUESTED":
      return "warning";
    case "CONFIRMED":
      return "success";
    case "CANCELED":
    case "CANCELLED":
    case "REJECTED":
      return "danger";
    default:
      return "dark";
  }
};

export const getHoursText = (hours, locale = "ca-ES") => {
  if (!hours) {
    return null;
  }

  const days = hours / 24;

  switch (simplifyLocale(locale)) {
    case "de":
      if (hours > 48) return `${days} ${days !== 1 ? "Tage" : "Tag"}`;
      return `${hours} ${hours !== 1 ? "Stunden" : "Stunde"}`;
    case "en":
      if (hours > 48) return `${days} ${days !== 1 ? "days" : "day"}`;
      return `${hours} ${hours !== 1 ? "hours" : "hour"}`;
    case "ca":
      if (hours > 48) return `${days} ${days !== 1 ? "dies" : "dia"}`;
      return `${hours} ${hours !== 1 ? "hores" : "hora"}`;
    case "es":
      if (hours > 48) return `${days} ${days !== 1 ? "días" : "día"}`;
      return `${hours} ${hours !== 1 ? "horas" : "hora"}`;
    default:
      if (hours > 48) return `${days} ${days !== 1 ? "days" : "day"}`;
      return `${hours} ${hours !== 1 ? "hours" : "hour"}`;
  }
};

export const getNightsText = (nights, locale = "ca-ES") => {
  if (!nights || nights === 0) {
    return null;
  }

  switch (simplifyLocale(locale)) {
    case "de":
      return `${nights} ${nights !== 1 ? "Nächte" : "Nacht"}`;
    case "en":
      return `${nights} ${nights !== 1 ? "nights" : "night"}`;
    case "ca":
      return `${nights} ${nights !== 1 ? "nits" : "nit"}`;
    case "es":
      return `${nights} ${nights !== 1 ? "noches" : "noche"}`;
    default:
      return `${nights} ${nights !== 1 ? "nights" : "night"}`;
  }
};

export const getAdultsText = (adults, locale = "ca-ES") => {
  if (!adults && adults !== 0) {
    return null;
  }

  switch (simplifyLocale(locale)) {
    case "de":
      if (adults === 0) return "0 Erwachsene";
      if (adults !== 1) return `${adults} Erwachsene`;
      return "1 Erwachsener";
    case "en":
      if (adults === 0) return "0 adults";
      if (adults !== 1) return `${adults} adults`;
      return "1 adult";
    case "ca":
      if (adults === 0) return "0 adults";
      if (adults !== 1) return `${adults} adults`;
      return "1 adult";
    case "es":
      if (adults === 0) return "0 adultos";
      if (adults !== 1) return `${adults} adultos`;
      return "1 adulto";
    default:
      if (adults === 0) return "0 adults";
      if (adults !== 1) return `${adults} adults`;
      return "1 adult";
  }
};

export const getChildrensText = (children, locale = "ca-ES") => {
  if (!children && children !== 0) {
    return null;
  }

  switch (simplifyLocale(locale)) {
    case "de":
      if (children === 0) return "0 Kinder";
      if (children !== 1) return `${children} Kinder`;
      return "1 Kind";
    case "en":
      if (children === 0) return "0 children";
      if (children !== 1) return `${children} children`;
      return "1 child";
    case "ca":
      if (children === 0) return "0 nins/es";
      if (children !== 1) return `${children} nins/es`;
      return "1 nin/a";
    case "es":
      if (children === 0) return "0 niños/as";
      if (children !== 1) return `${children} niños/as`;
      return "1 niño/a";
    default:
      if (children === 0) return "0 children";
      if (children !== 1) return `${children} children`;
      return "1 child";
  }
};

export const getBabiesText = (babies, locale = "ca-ES") => {
  if (!babies && babies !== 0) {
    return null;
  }

  switch (simplifyLocale(locale)) {
    case "de":
      if (babies === 0) return "0 Babys";
      if (babies !== 1) return `${babies} Babys`;
      return "1 Baby";
    case "en":
      if (babies === 0) return "0 babies";
      if (babies !== 1) return `${babies} babies`;
      return "1 baby";
    case "ca":
      if (babies === 0) return "0 nadons";
      if (babies !== 1) return `${babies} nadons`;
      return "1 nadó";
    case "es":
      if (babies === 0) return "0 bebés";
      if (babies !== 1) return `${babies} bebés`;
      return "1 bebé";
    default:
      if (babies === 0) return "0 babies";
      if (babies !== 1) return `${babies} babies`;
      return "1 baby";
  }
};

export const getGuestsText = (guests, locale = "ca-ES") => {
  if (!guests || guests.length === 0) {
    return null;
  }

  const text = [];
  if (guests.adults > 0) text.push(getAdultsText(guests.adults, locale));
  if (guests.children > 0) text.push(getChildrensText(guests.children, locale));
  if (guests.babies > 0) text.push(getBabiesText(guests.babies, locale));
  return text.join(", ");
};

export const getTransportName = (code, locale = "ca-ES") => {
  if (!code) {
    return null;
  }

  switch (simplifyLocale(locale)) {
    case "de":
      switch (code.toUpperCase()) {
        case "PLANE":
          return "Flugzeug";
        case "BOAT":
          return "Fähre";
        case "NONE":
          return "Kein Transport";
        default:
          return code.toUpperCase();
      }
    case "en":
      switch (code.toUpperCase()) {
        case "PLANE":
          return "Plane";
        case "BOAT":
          return "Ferry";
        case "NONE":
          return "No transport";
        default:
          return code.toUpperCase();
      }
    case "ca":
      switch (code.toUpperCase()) {
        case "PLANE":
          return "Avió";
        case "BOAT":
          return "Vaixell";
        case "NONE":
          return "Sense transport";
        default:
          return code.toUpperCase();
      }
    case "es":
      switch (code.toUpperCase()) {
        case "PLANE":
          return "Avión";
        case "BOAT":
          return "Barco";
        case "NONE":
          return "Sin transporte";
        default:
          return code.toUpperCase();
      }
    default:
      switch (code.toUpperCase()) {
        case "PLANE":
          return "Plane";
        case "BOAT":
          return "Boat";
        case "NONE":
          return "No transport";
        default:
          return code.toUpperCase();
      }
  }
};

export const getGenderName = (code, locale = "ca-ES") => {
  if (!code) {
    return null;
  }

  switch (simplifyLocale(locale)) {
    case "de":
      switch (code.toUpperCase()) {
        case "MALE":
          return "Mann";
        case "FEMALE":
          return "Frau";
        case "OTHER":
          return "Andere";
        default:
          return code.toUpperCase();
      }
    case "en":
      switch (code.toUpperCase()) {
        case "MALE":
          return "Man";
        case "FEMALE":
          return "Woman";
        case "OTHER":
          return "Other";
        default:
          return code.toUpperCase();
      }
    case "ca":
      switch (code.toUpperCase()) {
        case "MALE":
          return "Home";
        case "FEMALE":
          return "Dona";
        case "OTHER":
          return "Altre";
        default:
          return code.toUpperCase();
      }
    case "es":
      switch (code.toUpperCase()) {
        case "MALE":
          return "Hombre";
        case "FEMALE":
          return "Mujer";
        case "OTHER":
          return "Otro";
        default:
          return code.toUpperCase();
      }
    default:
      switch (code.toUpperCase()) {
        case "MALE":
          return "Man";
        case "FEMALE":
          return "Woman";
        case "OTHER":
          return "Other";
        default:
          return code.toUpperCase();
      }
  }
};

export const getIdTypeName = (code, locale = "ca-ES") => {
  if (!code) {
    return null;
  }

  switch (simplifyLocale(locale)) {
    case "de":
      switch (code.toUpperCase()) {
        case "PASSPORT":
          return "Reisepass";
        case "DNI":
          return "Personalausweis";
        case "CIF":
          return "Umsatzsteuer-Identifikationsnummer";
        default:
          return code.toUpperCase();
      }
    case "en":
      switch (code.toUpperCase()) {
        case "PASSPORT":
          return "Passport";
        case "DNI":
          return "National ID";
        case "CIF":
          return "VAT number";
        default:
          return code.toUpperCase();
      }
    case "ca":
      switch (code.toUpperCase()) {
        case "PASSPORT":
          return "Passaport";
        default:
          return code.toUpperCase();
      }
    case "es":
      switch (code.toUpperCase()) {
        case "PASSPORT":
          return "Pasaporte";
        default:
          return code.toUpperCase();
      }
    default:
      switch (code.toUpperCase()) {
        case "PASSPORT":
          return "Passport";
        case "DNI":
          return "National ID";
        case "CIF":
          return "VAT number";
        default:
          return code.toUpperCase();
      }
  }
};

export const getClientPaymentScopeName = (code, locale = "ca-ES") => {
  if (!code) {
    return null;
  }

  switch (simplifyLocale(locale)) {
    case "de":
      switch (code.toUpperCase()) {
        case "BOOKING":
          return "Buchung";
        case "SECURITY_DEPOSIT":
          return "Kaution";
        case "SERVICE":
          return "Service";
        case "OTHER":
          return "Andere";
        default:
          return code.toUpperCase();
      }
    case "en":
      switch (code.toUpperCase()) {
        case "BOOKING":
          return "Booking";
        case "SECURITY_DEPOSIT":
          return "Security deposit";
        case "SERVICE":
          return "Service";
        case "OTHER":
          return "Other";
        default:
          return code.toUpperCase();
      }
    case "ca":
      switch (code.toUpperCase()) {
        case "BOOKING":
          return "Reserva";
        case "SECURITY_DEPOSIT":
          return "Fiança";
        case "SERVICE":
          return "Servei";
        case "OTHER":
          return "Altre";
        default:
          return code.toUpperCase();
      }
    case "es":
      switch (code.toUpperCase()) {
        case "BOOKING":
          return "Reserva";
        case "SECURITY_DEPOSIT":
          return "Fianza";
        case "SERVICE":
          return "Servicio";
        case "OTHER":
          return "Otro";
        default:
          return code.toUpperCase();
      }
    default:
      switch (code.toUpperCase()) {
        case "BOOKING":
          return "Booking";
        case "SECURITY_DEPOSIT":
          return "Security deposit";
        case "SERVICE":
          return "Service";
        case "OTHER":
          return "Other";
        default:
          return code.toUpperCase();
      }
  }
};

export const getServiceName = (code, locale = "ca-ES") => {
  if (!code) {
    return null;
  }

  switch (simplifyLocale(locale)) {
    case "de":
      switch (code.toUpperCase()) {
        case "AIR_CONDITIONING":
          return "Klimaanlage";
        case "TOURIST_TAX":
          return "Kurtaxe";
        case "SECURITY_DEPOSIT":
          return "Kaution";
        case "CLEANING":
          return "Reinigung";
        case "ADDITIONAL_CLEANING":
          return "Zusätzliche Reinigung";
        case "FINAL_CLEANING":
          return "Endreinigung";
        case "AIRPORT_TRANSFER":
          return "Flughafentransfer";
        case "TRANSFER":
          return "Transfer";
        case "CAR_RENTAL":
          return "Autovermietung";
        case "HEATED_POOL":
        case "HEATED_SWIMMING_POOL":
        case "POOL_HEATING":
          return "Schwimmbadheizung";
        case "ELECTRICITY_CONSUMPTION":
          return "Stromverbrauch";
        case "HEATING_CONSUMPTION":
          return "Heizverbrauch";
        case "CLIMA_CONSUMPTION":
          return "Klimaanlagenverbrauch";
        case "FIREWOOD":
          return "Brennholz";
        case "BABY_COT":
          return "Babybett";
        case "BABY_CHAIR":
          return "Babystuhl";
        case "JACUZZI":
          return "Whirlpool";
        case "SUPERMARKET":
          return "Supermarkt";
        case "CHEF_AT_HOME":
          return "Hauskoch";
        case "EXTRA_SINGLE_BED":
          return "Zusätzliches Einzelbett";
        case "DAMAGES":
          return "Brüche und Schäden";
        case "OTHER":
          return "Andere";
        default:
          return code.toUpperCase();
      }
    case "en":
      switch (code.toUpperCase()) {
        case "AIR_CONDITIONING":
          return "A/C";
        case "TOURIST_TAX":
          return "Tourist tax";
        case "SECURITY_DEPOSIT":
          return "Security deposit";
        case "CLEANING":
          return "Cleaning";
        case "ADDITIONAL_CLEANING":
          return "Additional cleaning";
        case "FINAL_CLEANING":
          return "Final cleaning";
        case "AIRPORT_TRANSFER":
          return "Airport transfer";
        case "TRANSFER":
          return "Transfer";
        case "CAR_RENTAL":
          return "Car rental";
        case "HEATED_POOL":
        case "HEATED_SWIMMING_POOL":
        case "POOL_HEATING":
          return "Swimming pool heating";
        case "ELECTRICITY_CONSUMPTION":
          return "Electricity consumption";
        case "HEATING_CONSUMPTION":
          return "Heating consumption";
        case "CLIMA_CONSUMPTION":
          return "A/C consumption";
        case "FIREWOOD":
          return "Firewood";
        case "BABY_COT":
          return "Baby cot";
        case "BABY_CHAIR":
          return "Baby chair";
        case "JACUZZI":
          return "Jacuzzi";
        case "SUPERMARKET":
          return "Supermarket";
        case "CHEF_AT_HOME":
          return "Home Chef";
        case "EXTRA_SINGLE_BED":
          return "Extra single bed";
        case "DAMAGES":
          return "Breaks and damages";
        case "OTHER":
          return "Other";
        default:
          return code.toUpperCase();
      }
    case "ca":
      switch (code.toUpperCase()) {
        case "AIR_CONDITIONING":
          return "Aire Acondicionat";
        case "TOURIST_TAX":
          return "Impost turístic";
        case "SECURITY_DEPOSIT":
          return "Fiança";
        case "CLEANING":
          return "Neteja";
        case "ADDITIONAL_CLEANING":
          return "Neteja addicional";
        case "FINAL_CLEANING":
          return "Neteja final";
        case "AIRPORT_TRANSFER":
          return "Transfer aeroport";
        case "TRANSFER":
          return "Transfer";
        case "CAR_RENTAL":
          return "Lloguer de cotxe";
        case "HEATED_POOL":
        case "HEATED_SWIMMING_POOL":
        case "POOL_HEATING":
          return "Piscina climatitzada";
        case "ELECTRICITY_CONSUMPTION":
          return "Consum elèctric";
        case "HEATING_CONSUMPTION":
          return "Consum calefacció";
        case "CLIMA_CONSUMPTION":
          return "Consum climatització";
        case "FIREWOOD":
          return "Llenya";
        case "BABY_COT":
          return "Cuna";
        case "BABY_CHAIR":
          return "Trona";
        case "JACUZZI":
          return "Jacuzzi";
        case "SUPERMARKET":
          return "Supermercat";
        case "CHEF_AT_HOME":
          return "Chef a domicili";
        case "EXTRA_SINGLE_BED":
          return "Llit individual extra";
        case "DAMAGES":
          return "Rotures i desperfectes";
        case "OTHER":
          return "Altre";
        default:
          return code.toUpperCase();
      }
    case "es":
      switch (code.toUpperCase()) {
        case "AIR_CONDITIONING":
          return "Aire Acondicionado";
        case "TOURIST_TAX":
          return "Impuesto turístico";
        case "SECURITY_DEPOSIT":
          return "Fianza";
        case "CLEANING":
          return "Limpieza";
        case "ADDITIONAL_CLEANING":
          return "Limpieza adicional";
        case "FINAL_CLEANING":
          return "Limpieza final";
        case "AIRPORT_TRANSFER":
          return "Transfer aeropuerto";
        case "TRANSFER":
          return "Transfer";
        case "CAR_RENTAL":
          return "Alquiler de coche";
        case "HEATED_POOL":
        case "HEATED_SWIMMING_POOL":
        case "POOL_HEATING":
          return "Piscina climatizada";
        case "ELECTRICITY_CONSUMPTION":
          return "Consumo eléctrico";
        case "HEATING_CONSUMPTION":
          return "Consumo calefacción";
        case "CLIMA_CONSUMPTION":
          return "Consumo climatización";
        case "FIREWOOD":
          return "Leña";
        case "BABY_COT":
          return "Cuna";
        case "BABY_CHAIR":
          return "Trona";
        case "JACUZZI":
          return "Jacuzzi";
        case "SUPERMARKET":
          return "Supermercado";
        case "CHEF_AT_HOME":
          return "Chef a domicilio";
        case "EXTRA_SINGLE_BED":
          return "Cama individual extra";
        case "DAMAGES":
          return "Rupturas y desperfectos";
        case "OTHER":
          return "Otro";
        default:
          return code.toUpperCase();
      }
    default:
      switch (code.toUpperCase()) {
        case "AIR_CONDITIONING":
          return "A/C";
        case "TOURIST_TAX":
          return "Tourist tax";
        case "SECURITY_DEPOSIT":
          return "Security deposit";
        case "CLEANING":
          return "Cleaning";
        case "ADDITIONAL_CLEANING":
          return "Additional cleaning";
        case "FINAL_CLEANING":
          return "Final cleaning";
        case "AIRPORT_TRANSFER":
          return "Airport transfer";
        case "TRANSFER":
          return "Transfer";
        case "CAR_RENTAL":
          return "Car rental";
        case "HEATED_POOL":
        case "HEATED_SWIMMING_POOL":
        case "POOL_HEATING":
          return "Swimming pool heating";
        case "ELECTRICITY_CONSUMPTION":
          return "Electricity consumption";
        case "HEATING_CONSUMPTION":
          return "Heating consumption";
        case "CLIMA_CONSUMPTION":
          return "A/C consumption";
        case "FIREWOOD":
          return "Firewood";
        case "BABY_COT":
          return "Baby cot";
        case "BABY_CHAIR":
          return "Baby chair";
        case "JACUZZI":
          return "Jacuzzi";
        case "SUPERMARKET":
          return "Supermarket";
        case "CHEF_AT_HOME":
          return "Home Chef";
        case "EXTRA_SINGLE_BED":
          return "Extra single bed";
        case "DAMAGES":
          return "Breaks and damages";
        case "OTHER":
          return "Other";
        default:
          return code.toUpperCase();
      }
  }
};

// Returns the total amount corresponding to the client of a given booking service
export const getBookingServiceClientTotalPrice = (bookingService, nights) => {
  if (!bookingService) return null;

  // If the payment frequency depends on consumption, the total price
  // is uncalculabe, return null
  if (
    bookingService.paymentFrequency &&
    UNCALCULABLE_FREQUENCIES.includes(bookingService.paymentFrequency)
  ) {
    return null;
  }

  let serviceTotalPrice = bookingService.pvpPrice;

  // If the payment frequency is daily, calculate the total price keeping
  // in mind the duration of the reservation
  if (bookingService.paymentFrequency === "DAY" && nights) {
    serviceTotalPrice = bookingService.pvpPrice * nights;
  }

  return serviceTotalPrice;
};

// Returns the text version of the unit price corresponding to the client of a given booking service
export const getBookingServiceClientUnitPriceText = (
  bookingService,
  locale
) => {
  if (!bookingService) return null;
  if (!bookingService.pvpPrice && bookingService.pvpPrice !== 0) return null;
  if (!bookingService.paymentFrequency) return null;

  const bookingServiceUnitPrice = formatCurrency(bookingService.pvpPrice);
  const paymentFrequencyUnit =
    getPaymentFrequencyUnit(bookingService.paymentFrequency, locale) || "";

  return `${bookingServiceUnitPrice}${paymentFrequencyUnit}`;
};

// Returns the text version of the total price corresponding to the client of a given booking service
export const getBookingServiceClientTotalPriceText = (
  bookingService,
  nights,
  locale
) => {
  if (!bookingService) return null;

  const bookingServicePrice = getBookingServiceClientTotalPrice(
    bookingService,
    nights
  );

  // If the booking services has no payment frequency or it has a daily payment frequency (it can be calculated in advance),
  // return the calculated total.
  if (
    !bookingService?.paymentFrequency ||
    bookingService.paymentFrequency === "DAY"
  )
    return formatCurrency(bookingServicePrice);

  // If not, return the unit pvp price and the unit text
  return getBookingServiceClientUnitPriceText(bookingService, locale);
};

// Returns the name of a given booking service based on the service code
export const getBookingServiceName = (bookingService, locale = "ca-ES") => {
  if (!bookingService?.service) return null;
  return getServiceName(bookingService.service.code, locale);
};

// Returns the name of a given booking service based on the service code, and
// the text version of the unit price corresponding to the client
export const getBookingServiceNameWithClientUnitPrice = (
  bookingService,
  locale = "ca-ES"
) => {
  if (!bookingService) return null;

  const name = [];
  if (bookingService?.service?.code)
    name.push(getServiceName(bookingService.service.code, locale));
  const unitPriceText = getBookingServiceClientUnitPriceText(
    bookingService,
    locale
  );
  if (unitPriceText) name.push(`(${unitPriceText})`);

  return name.join(" ");
};

// Returns the text version of the unit price corresponding to the client of a given accommodation service
export const getAccommodationServiceClientUnitPriceText = (
  accommodationService,
  locale
) => {
  if (!accommodationService) return null;
  if (!accommodationService.price && accommodationService.price !== 0)
    return null;
  if (!accommodationService.paymentFrequency) return null;

  const accommodationServiceUnitPrice = formatCurrency(
    accommodationService.price
  );
  const paymentFrequencyUnit =
    getPaymentFrequencyUnit(accommodationService.paymentFrequency, locale) ||
    "";

  return `${accommodationServiceUnitPrice}${paymentFrequencyUnit}`;
};

export const scrollToId = (elementId, offset) => {
  const element = document.getElementById(elementId);
  const elementPosition = element.getBoundingClientRect().top;
  const scrolledDistance = document.documentElement.scrollTop;
  const offsetPosition = scrolledDistance + elementPosition - offset;

  window.scrollTo({
    top: offsetPosition,
    behavior: "smooth"
  });
};

export const getTpvLink = ({
  locale = "en-GB",
  amount = null,
  email = null,
  comment = null,
  name = null,
  localizator = null,
  accommodation = null,
  checkin = null,
  checkout = null
}) => {
  const link = [`${TPV_BASE_LINK}/${simplifyLocale(locale)}?`];
  const params = [];
  if (amount) params.push(`amount=${amount}`);
  if (email) params.push(`email=${email}`);
  if (comment) params.push(`comment=${comment}`);
  if (name) params.push(`name=${name}`);
  if (localizator) params.push(`localizator=${localizator}`);
  if (accommodation) params.push(`accommodation=${accommodation}`);
  if (checkin) params.push(`checkin=${checkin}`);
  if (checkout) params.push(`checkout=${checkout}`);
  return `${link}${params.join("&")}`;
};
