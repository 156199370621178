import countries from "@/utils/countries";

export const formatCountryName = (countryCode, locale = "es-ES") => {
  if (!countryCode) return null;

  const country = countries.find(c => c.code === countryCode);
  if (country) {
    switch (locale) {
      case "es":
        return country.name_es;
      case "en":
        return country.name_en;
      default:
        return countryCode;
    }
  }
  return countryCode;
};

export const formatCurrency = amount => {
  if (!amount && amount !== 0) return null;

  return new Intl.NumberFormat("es-ES", {
    style: "currency",
    currency: "EUR",
    maximumFractionDigits: 2,
    minimumFractionDigits: 0
  }).format(amount / 100);
};

//
// STRING DATE FORMATTERS
//

/**
 * Format and return date in Humanize format
 * Intl docs: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/format
 * Intl Constructor: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
 * @param {String} value date to format. The date string must be in format YYYY-MM-DDTHH:MM:SS
 * @param {Object} formatting Intl object to format with
 */
export const formatDateStringToDate = (
  value,
  locale = "ca-ES",
  formatting = { month: "2-digit", day: "2-digit", year: "numeric" }
) => {
  if (!value || typeof value !== "string") return null;

  const stringDateArray = value.split("T");

  let dateArray = stringDateArray[0];
  if (!dateArray) return null;

  dateArray = dateArray.split("-");
  if (dateArray.length !== 3) return null;

  // Create a date with the extracted date form the dateString
  let date = new Date(
    parseInt(dateArray[0], 10), // Day
    parseInt(dateArray[1] - 1, 10), // MonthIndex
    parseInt(dateArray[2], 10) // Year
  );
  const formattedDate = new Intl.DateTimeFormat(locale, formatting).format(
    date
  );

  // If the stringDate does not include a valid time string, return the formatted date
  if (stringDateArray.length < 2) return formattedDate;
  let timeArray = stringDateArray[1];
  if (!timeArray) return formattedDate;
  // eslint-disable-next-line prefer-destructuring
  timeArray = timeArray.split("+")[0];
  if (!timeArray) return formattedDate;
  timeArray = timeArray.split(":");
  if (timeArray.length < 2) return formattedDate;

  // Create the date with the exctracted date and time from the dateString
  date = new Date(
    parseInt(dateArray[0], 10), // Day
    parseInt(dateArray[1] - 1, 10), // MonthIndex
    parseInt(dateArray[2], 10), // Year
    parseInt(timeArray[0], 10), // Hour
    parseInt(timeArray[1], 10) // Minute
  );

  return new Intl.DateTimeFormat(locale, formatting).format(date);
};

export const formatDateStringToDayDayShortMonthYear = (
  value,
  locale = "ca-ES"
) => {
  if (!value || typeof value !== "string") return null;

  let dateArray = value.split("T")[0];
  if (!dateArray) return null;

  dateArray = dateArray.split("-");
  if (dateArray.length !== 3) return null;

  // Create a date with the extracted date form the dateString
  const date = new Date(
    parseInt(dateArray[0], 10), // Day
    parseInt(dateArray[1] - 1, 10), // MonthIndex
    parseInt(dateArray[2], 10) // Year
  );

  const text = [];

  const day = new Intl.DateTimeFormat(locale, { day: "numeric" }).format(date);
  if (day) text.push(day);

  const month = new Intl.DateTimeFormat(locale, { month: "short" }).format(
    date
  );
  if (month) text.push(month);

  const year = new Intl.DateTimeFormat(locale, { year: "numeric" }).format(
    date
  );
  if (year) text.push(year);

  return text.length ? text.join(" ") : null;
};

export const formatDateStringToDatabaseDate = value => {
  if (!value || typeof value !== "string") return null;

  const stringDateArray = value.split("T");

  let dateArray = stringDateArray[0];
  if (!dateArray) return null;

  dateArray = dateArray.split("-");
  if (dateArray.length !== 3) return null;

  function twoDigits(d) {
    if (d >= 0 && d < 10) return `0${d.toString()}`;
    if (d > -10 && d < 0) return `-0${(-1 * d).toString()}`;
    return d.toString();
  }

  // Create a date with the extracted date form the dateString
  const date = new Date(
    parseInt(dateArray[0], 10), // Day
    parseInt(dateArray[1] - 1, 10), // MonthIndex
    parseInt(dateArray[2], 10) // Year
  );

  return `${twoDigits(date.getFullYear())}-${twoDigits(
    date.getMonth() + 1
  )}-${twoDigits(date.getDate())}`;
};

export const formatDateStringToDatabaseDateTime = value => {
  if (!value || typeof value !== "string") return null;

  const stringDateArray = value.split("T");

  let dateArray = stringDateArray[0];
  if (!dateArray) return null;

  dateArray = dateArray.split("-");
  if (dateArray.length !== 3) return null;

  function twoDigits(d) {
    if (d >= 0 && d < 10) return `0${d.toString()}`;
    if (d > -10 && d < 0) return `-0${(-1 * d).toString()}`;
    return d.toString();
  }

  // Create a date with the extracted date form the dateString
  let date = new Date(
    parseInt(dateArray[0], 10), // Day
    parseInt(dateArray[1] - 1, 10), // MonthIndex
    parseInt(dateArray[2], 10) // Year
  );
  const formattedDate = `${twoDigits(date.getFullYear())}-${twoDigits(
    date.getMonth() + 1
  )}-${twoDigits(date.getDate())} 00:00:00`;

  // If the stringDate does not include a valid time string, return the formatted date
  if (stringDateArray.length < 2) return formattedDate;
  let timeArray = stringDateArray[1];
  if (!timeArray) return formattedDate;
  // eslint-disable-next-line prefer-destructuring
  timeArray = timeArray.split("+")[0];
  if (!timeArray) return formattedDate;
  timeArray = timeArray.split(":");
  if (timeArray.length < 2) return formattedDate;

  // Create the date with the exctracted date and time from the dateString
  date = new Date(
    parseInt(dateArray[0], 10), // Day
    parseInt(dateArray[1] - 1, 10), // MonthIndex
    parseInt(dateArray[2], 10), // Year
    parseInt(timeArray[0], 10), // Hour
    parseInt(timeArray[1], 10) // Minute
  );

  return `${twoDigits(date.getFullYear())}-${twoDigits(
    date.getMonth() + 1
  )}-${twoDigits(
    date.getDate()
  )} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;
};

//
// DATE OBJECT FORMATTERS
//

/**
 * Format and return date in Humanize format
 * Intl docs: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/format
 * Intl Constructor: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
 * @param {Date} value date to format. The date must be a javascript Date object
 * @param {Object} formatting Intl object to format with
 */
export const formatDateObjectToDate = (
  value,
  locale = "ca-ES",
  formatting = { month: "2-digit", day: "2-digit", year: "numeric" }
) => {
  if (!value || !(value instanceof Date)) return null;
  return new Intl.DateTimeFormat(locale, formatting).format(value);
};

export const formatDateObjectToDatabaseDate = value => {
  if (!value || !(value instanceof Date)) return null;

  function twoDigits(d) {
    if (d >= 0 && d < 10) return `0${d.toString()}`;
    if (d > -10 && d < 0) return `-0${(-1 * d).toString()}`;
    return d.toString();
  }

  return `${twoDigits(value.getFullYear())}-${twoDigits(
    value.getMonth() + 1
  )}-${twoDigits(value.getDate())}`;
};

export const formatDateObjectToDatabaseDateTime = value => {
  if (!value || !(value instanceof Date)) return null;

  function twoDigits(d) {
    if (d >= 0 && d < 10) return `0${d.toString()}`;
    if (d > -10 && d < 0) return `-0${(-1 * d).toString()}`;
    return d.toString();
  }

  return `${twoDigits(value.getFullYear())}-${twoDigits(
    value.getMonth() + 1
  )}-${twoDigits(
    value.getDate()
  )} ${value.getHours()}:${value.getMinutes()}:${value.getSeconds()}`;
};
