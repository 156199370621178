import axios from "@/axios";

export default {
  namespaced: true,
  state: {},
  getters: {
    isUserLoggedIn() {
      return !!localStorage.getItem("foravilaGuestsUserData");
    },
    isUserSessionExpired() {
      return true;
    }
  },
  mutations: {},
  actions: {
    loginBasic(context, loginInfo) {
      return new Promise((resolve, reject) => {
        if (!loginInfo) {
          reject();
          return;
        }
        axios
          .post("/login", {
            email: loginInfo.email,
            password: loginInfo.password
          })
          .then(response => {
            // If there's user data in response
            if (response.status === 204) resolve(response);
            else reject(new Error({ message: "Email o Clau incorrectes" }));
          })
          .catch(error => {
            if (error.response.status === 401)
              reject(new Error({ message: "Email o Clau incorrectes" }));
            else reject(new Error(error));
          });
      });
    },

    resetBasic() {
      // Reset the foravilaGuestsUserData on the local storage
      if (localStorage.getItem("foravilaGuestsUserData")) localStorage.removeItem("foravilaGuestsUserData");

      // Reset all the stores
      // dispatch('resetAppData', null, { root: true })
    },

    checkLoggedIn() {
      return new Promise(resolve => {
        axios
          .get("/me")
          .then(response => {
            if (response.status === 200) resolve(true);
            else resolve(false);
          })
          .catch(() => resolve(false));
      });
    },

    logout({ dispatch }) {
      return new Promise((resolve, reject) => {
        axios
          .get("/logout")
          .then(response => {
            dispatch("resetBasic");
            resolve(response.data);
          })
          .catch(error => reject(error.response));
      });
    },

    requestPasswordReset(context, passwordResetData) {
      return new Promise((resolve, reject) => {
        if (!passwordResetData) {
          reject();
          return;
        }
        axios
          .post("/reset_password/request", {
            email: passwordResetData.email,
            language: passwordResetData.language,
            host: passwordResetData.host
          })
          .then(response => resolve(response))
          .catch(error => reject(error.response));
      });
    },

    changePassword(context, passwordData) {
      return new Promise((resolve, reject) => {
        if (!passwordData) {
          reject();
          return;
        }
        axios
          .post("/reset_password/change", {
            token: passwordData.token,
            plainPassword: passwordData.plainPassword
          })
          .then(response => resolve(response))
          .catch(error => reject(error.response));
      });
    },

    register(context, userData) {
      return new Promise((resolve, reject) => {
        if (!userData) {
          reject();
          return;
        }
        axios
          .post("/register_client", {
            token: userData.token,
            email: userData.email,
            plainPassword: userData.plainPassword
          })
          .then(response => resolve(response))
          .catch(error => reject(error.response));
      });
    },

    validClientToken(context, token) {
      return new Promise((resolve, reject) => {
        if (!token) {
          reject();
          return;
        }
        axios
          .post("/check_client_token", { token })
          .then(() => resolve(true))
          .catch(() => resolve(false));
      });
    }
  }
};
