export default [
  {
    name_en: "Afghanistán",
    name_de: "Afghanistan",
    name_ca: "Afganistan",
    name_es: "Afganistan",
    dial_code: "+93",
    code: "AF"
  },
  {
    name_en: "Albania",
    name_de: "Albanien",
    name_ca: "Albània",
    name_es: "Albania",
    dial_code: "+355",
    code: "AL"
  },
  {
    name_en: "Algeria",
    name_de: "Algerien",
    name_ca: "Algèria",
    name_es: "Argelia",
    dial_code: "+213",
    code: "DZ"
  },
  {
    name_en: "American Samoa",
    name_de: "Amerikanisch-Samoa",
    name_ca: "Samoa Americana",
    name_es: "Samoa Americana",
    dial_code: "+1 684",
    code: "AS"
  },
  {
    name_en: "Andorra",
    name_de: "Andorra",
    name_ca: "Andorra",
    name_es: "Andorra",
    dial_code: "+376",
    code: "AD"
  },
  {
    name_en: "Angola",
    name_de: "Angola",
    name_ca: "Angola",
    name_es: "Angola",
    dial_code: "+244",
    code: "AO"
  },
  {
    name_en: "Anguilla",
    name_de: "Anguilla",
    name_ca: "Anguilla",
    name_es: "Anguilla",
    dial_code: "+1 264",
    code: "AI"
  },
  {
    name_en: "Antarctica",
    name_de: "Antarktis",
    name_ca: "Antartida",
    name_es: "Antartida",
    dial_code: "+672",
    code: "AQ"
  },
  {
    name_en: "Antigua and Barbuda",
    name_de: "Antigua und Barbuda",
    name_ca: "Antigua i Barbuda",
    name_es: "Antigua y Barbuda",
    dial_code: "+1268",
    code: "AG"
  },
  {
    name_en: "Argentina",
    name_de: "Argentinien",
    name_ca: "Argentina",
    name_es: "Argentina",
    dial_code: "+54",
    code: "AR"
  },
  {
    name_en: "Armenia",
    name_de: "Armenien",
    name_ca: "Armènia",
    name_es: "Armenia",
    dial_code: "+374",
    code: "AM"
  },
  {
    name_en: "Aruba",
    name_de: "Aruba",
    name_ca: "Aruba",
    name_es: "Aruba",
    dial_code: "+297",
    code: "AW"
  },
  {
    name_en: "Australia",
    name_de: "Australien",
    name_ca: "Austràlia",
    name_es: "Australia",
    dial_code: "+61",
    code: "AU"
  },
  {
    name_en: "Austria",
    name_de: "Österreich",
    name_ca: "Àustria",
    name_es: "Austria",
    dial_code: "+43",
    code: "AT"
  },
  {
    name_en: "Azerbaijan",
    name_de: "Aserbaidschan",
    name_ca: "Azerbaidjan",
    name_es: "Azerbaiyan",
    dial_code: "+994",
    code: "AZ"
  },
  {
    name_en: "Bahamas",
    name_de: "Bahamas",
    name_ca: "Bahames",
    name_es: "Bahamas",
    dial_code: "+1 242",
    code: "BS"
  },
  {
    name_en: "Bahrain",
    name_de: "Bahrain",
    name_ca: "Bahrain",
    name_es: "Barein",
    dial_code: "+973",
    code: "BH"
  },
  {
    name_en: "Bangladesh",
    name_de: "Bangladesch",
    name_ca: "Bangla Desh",
    name_es: "Banglades",
    dial_code: "+880",
    code: "BD"
  },
  {
    name_en: "Barbados",
    name_de: "Barbados",
    name_ca: "Barbados",
    name_es: "Barbados",
    dial_code: "+1 246",
    code: "BB"
  },
  {
    name_en: "Belarus",
    name_de: "Belarus",
    name_ca: "Bielorússia",
    name_es: "Bielorrusia",
    dial_code: "+375",
    code: "BY"
  },
  {
    name_en: "Belgium",
    name_de: "Belgien",
    name_ca: "Belgica",
    name_es: "Belgica",
    dial_code: "+32",
    code: "BE"
  },
  {
    name_en: "Belize",
    name_de: "Belize",
    name_ca: "Belize",
    name_es: "Belice",
    dial_code: "+501",
    code: "BZ"
  },
  {
    name_en: "Benin",
    name_de: "Benin",
    name_ca: "Benín",
    name_es: "Benin",
    dial_code: "+229",
    code: "BJ"
  },
  {
    name_en: "Bermuda",
    name_de: "Bermuda",
    name_ca: "Bermudes",
    name_es: "Bermudas",
    dial_code: "+1 441",
    code: "BM"
  },
  {
    name_en: "Bhutan",
    name_de: "Bhutan",
    name_ca: "Butan",
    name_es: "Butan",
    dial_code: "+975",
    code: "BT"
  },
  {
    name_en: "Bolivia",
    name_de: "Bolivien",
    name_ca: "Bolívia",
    name_es: "Bolivia",
    dial_code: "+591",
    code: "BO"
  },
  {
    name_en: "Bosnia and Herzegovina",
    name_de: "Bosnien und Herzegowina",
    name_ca: "Bòsnia i Hercegovina",
    name_es: "Bosnia-Herzegovina",
    dial_code: "+387",
    code: "BA"
  },
  {
    name_en: "Botswana",
    name_de: "Botsuana",
    name_ca: "Botswana",
    name_es: "Botsuana",
    dial_code: "+267",
    code: "BW"
  },
  {
    name_en: "Brazil",
    name_de: "Brasilien",
    name_ca: "Brasil",
    name_es: "Brasil",
    dial_code: "+55",
    code: "BR"
  },
  {
    name_en: "British Indian Ocean Territory",
    name_de: "Britisches Territorium im Indischen Ozean",
    name_ca: "Territori Britànic de l'Oceà Índic",
    name_es: "Territorio Británico del Océano Índico",
    dial_code: "+246",
    code: "IO"
  },
  {
    name_en: "Brunei Darussalam",
    name_de: "Brunei Darussalam",
    name_ca: "Brunei",
    name_es: "Brunei",
    dial_code: "+673",
    code: "BN"
  },
  {
    name_en: "Bulgaria",
    name_de: "Bulgarien",
    name_ca: "Bulgària",
    name_es: "Bulgaria",
    dial_code: "+359",
    code: "BG"
  },
  {
    name_en: "Burkina Faso",
    name_de: "Burkina Faso",
    name_ca: "Burkina Faso",
    name_es: "Burkina Faso",
    dial_code: "+226",
    code: "BF"
  },
  {
    name_en: "Burundi",
    name_de: "Burundi",
    name_ca: "Burundi",
    name_es: "Burundi",
    dial_code: "+257",
    code: "BI"
  },
  {
    name_en: "Cambodia",
    name_de: "Kambodscha",
    name_ca: "Cambodja",
    name_es: "Camboya",
    dial_code: "+855",
    code: "KH"
  },
  {
    name_en: "Cameroon",
    name_de: "Kamerun",
    name_ca: "Camerun",
    name_es: "Camerun",
    dial_code: "+237",
    code: "CM"
  },
  {
    name_en: "Canada",
    name_de: "Kanada",
    name_ca: "Canada",
    name_es: "Canada",
    dial_code: "+1",
    code: "CA"
  },
  {
    name_en: "Cape Verde",
    name_de: "Kap Verde",
    name_ca: "Cap Verd",
    name_es: "Cabo Verde",
    dial_code: "+238",
    code: "CV"
  },
  {
    name_en: "Cayman Islands",
    name_de: "Kaimaninseln",
    name_ca: "Illes Caiman",
    name_es: "Islas Caiman",
    dial_code: "+ 345",
    code: "KY"
  },
  {
    name_en: "Central African Republic",
    name_de: "Zentralafrikanische Republik",
    name_ca: "República Centreafricana",
    name_es: "Republica Centroafricana",
    dial_code: "+236",
    code: "CF"
  },
  {
    name_en: "Chad",
    name_de: "Tschad",
    name_ca: "Txad",
    name_es: "Chad",
    dial_code: "+235",
    code: "TD"
  },
  {
    name_en: "Chile",
    name_de: "Chile",
    name_ca: "Xile",
    name_es: "Chile",
    dial_code: "+56",
    code: "CL"
  },
  {
    name_en: "China",
    name_de: "China",
    name_ca: "Xina",
    name_es: "China",
    dial_code: "+86",
    code: "CN"
  },
  {
    name_en: "Christmas Island",
    name_de: "Weihnachtsinsel",
    name_ca: "Illa de Nadal",
    name_es: "Isla de Navidad",
    dial_code: "+61",
    code: "CX"
  },
  {
    name_en: "Cocos (Keeling) Islands",
    name_de: "Kokosinseln (Keelinginseln)",
    name_ca: "Illes Cocos",
    name_es: "Islas Cocos",
    dial_code: "+61",
    code: "CC"
  },
  {
    name_en: "Colombia",
    name_de: "Kolumbien",
    name_ca: "Colòmbia",
    name_es: "Colombia",
    dial_code: "+57",
    code: "CO"
  },
  {
    name_en: "Comoros",
    name_de: "Komoren",
    name_ca: "Comores",
    name_es: "Comoras",
    dial_code: "+269",
    code: "KM"
  },
  {
    name_en: "Congo",
    name_de: "Kongo",
    name_ca: "Congo",
    name_es: "Congo",
    dial_code: "+242",
    code: "CG"
  },
  {
    name_en: "Congo, The Democratic Republic of the",
    name_de: "Demokratische Republik Kongo",
    name_ca: "República Democràtica de Congo",
    name_es: "República Democrática del Congo",
    dial_code: "+243",
    code: "CD"
  },
  {
    name_en: "Cook Islands",
    name_de: "Cookinseln",
    name_ca: "Illes Cook",
    name_es: "Islas Cook",
    dial_code: "+682",
    code: "CK"
  },
  {
    name_en: "Costa Rica",
    name_de: "Costa Rica",
    name_ca: "Costa Rica",
    name_es: "Costa Rica",
    dial_code: "+506",
    code: "CR"
  },
  {
    name_en: "Cote d'Ivoire",
    name_de: "Elfenbeinküste",
    name_ca: "Costa d'Ivori",
    name_es: " Costa de Marfil",
    dial_code: "+225",
    code: "CI"
  },
  {
    name_en: "Croatia",
    name_de: "Kroatien",
    name_ca: "Croàcia",
    name_es: "Croacia",
    dial_code: "+385",
    code: "HR"
  },
  {
    name_en: "Cuba",
    name_de: "Kuba",
    name_ca: "Cuba",
    name_es: "Cuba",
    dial_code: "+53",
    code: "CU"
  },
  {
    name_en: "Cyprus",
    name_de: "Zypern",
    name_ca: "Xipre",
    name_es: "Chipre",
    dial_code: "+537",
    code: "CY"
  },
  {
    name_en: "Czech Republic",
    name_de: "Tschechische Republik",
    name_ca: "República Txeca",
    name_es: "Republica Checa ",
    dial_code: "+420",
    code: "CZ"
  },
  {
    name_en: "Denmark",
    name_de: "Dänemark",
    name_ca: "Dinamarca",
    name_es: "Dinamarca",
    dial_code: "+45",
    code: "DK"
  },
  {
    name_en: "Djibouti",
    name_de: "Dschibuti",
    name_ca: "Djibouti",
    name_es: " Yibuti",
    dial_code: "+253",
    code: "DJ"
  },
  {
    name_en: "Dominica",
    name_de: "Dominica",
    name_ca: "Dominique",
    name_es: "Dominica",
    dial_code: "+1 767",
    code: "DM"
  },
  {
    name_en: "Dominican Republic",
    name_de: "Dominikanische Republik",
    name_ca: "República Dominicana",
    name_es: "Republica Dominicana",
    dial_code: "+1 849",
    code: "DO"
  },
  {
    name_en: "Ecuador",
    name_de: "Ecuador",
    name_ca: "Equador",
    name_es: "Ecuador",
    dial_code: "+593",
    code: "EC"
  },
  {
    name_en: "Egypt",
    name_de: "Ägypten",
    name_ca: "Egipte",
    name_es: "Egipto",
    dial_code: "+20",
    code: "EG"
  },
  {
    name_en: "El Salvador",
    name_de: "El Salvador",
    name_ca: "El Salvador",
    name_es: "El Salvador",
    dial_code: "+503",
    code: "SV"
  },
  {
    name_en: "Equatorial Guinea",
    name_de: "Äquatorialguinea",
    name_ca: "Guinea Equatorial",
    name_es: "Guinea Ecuatorial",
    dial_code: "+240",
    code: "GQ"
  },
  {
    name_en: "Eritrea",
    name_de: "Eritrea",
    name_ca: "Eritrea",
    name_es: "Eritrea",
    dial_code: "+291",
    code: "ER"
  },
  {
    name_en: "Estonia",
    name_de: "Estland",
    name_ca: "Estònia",
    name_es: "Estonia",
    dial_code: "+372",
    code: "EE"
  },
  {
    name_en: "Ethiopia",
    name_de: "Äthiopien",
    name_ca: "Etiòpia",
    name_es: "Etiopia",
    dial_code: "+251",
    code: "ET"
  },
  {
    name_en: "Falkland Islands (Malvinas)",
    name_de: "Falklandinseln (Malwinen)",
    name_ca: "Illes Malvines",
    name_es: "Islas Malvinas",
    dial_code: "+500",
    code: "FK"
  },
  {
    name_en: "Faroe Islands",
    name_de: "Färöer-Inseln",
    name_ca: "Illes Fèroe",
    name_es: "Islas Feroe",
    dial_code: "+298",
    code: "FO"
  },
  {
    name_en: "Fiji",
    name_de: "Fidschi",
    name_ca: "Fiji",
    name_es: "Fiyi",
    dial_code: "+679",
    code: "FJ"
  },
  {
    name_en: "Finland",
    name_de: "Finnland",
    name_ca: "Finlàndia",
    name_es: "Finlandia",
    dial_code: "+358",
    code: "FI"
  },
  {
    name_en: "France",
    name_de: "Frankreich",
    name_ca: "França",
    name_es: "Francia",
    dial_code: "+33",
    code: "FR"
  },
  {
    name_en: "French Guiana",
    name_de: "Französisch-Guayana",
    name_ca: "Guaiana Francesa",
    name_es: "Guayana Francesa",
    dial_code: "+594",
    code: "GF"
  },
  {
    name_en: "French Polynesia",
    name_de: "Französisch-Polynesien",
    name_ca: "Polinèsia Francesa",
    name_es: "Polinesia Francesa",
    dial_code: "+689",
    code: "PF"
  },
  {
    name_en: "Gabon",
    name_de: "Gabun",
    name_ca: "Gabon",
    name_es: "Gabon",
    dial_code: "+241",
    code: "GA"
  },
  {
    name_en: "Gambia",
    name_de: "Gambia",
    name_ca: "Gàmbia",
    name_es: "Gambia",
    dial_code: "+220",
    code: "GM"
  },
  {
    name_en: "Georgia",
    name_de: "Georgien",
    name_ca: "Georgia",
    name_es: "Georgia",
    dial_code: "+995",
    code: "GE"
  },
  {
    name_en: "Germany",
    name_de: "Deutschland",
    name_ca: "Alemanya",
    name_es: "Alemania",
    dial_code: "+49",
    code: "DE"
  },
  {
    name_en: "Ghana",
    name_de: "Ghana",
    name_ca: "Ghana",
    name_es: "Ghana",
    dial_code: "+233",
    code: "GH"
  },
  {
    name_en: "Gibraltar",
    name_de: "Gibraltar",
    name_ca: "Gibraltar",
    name_es: "Gibraltar",
    dial_code: "+350",
    code: "GI"
  },
  {
    name_en: "Greece",
    name_de: "Griechenland",
    name_ca: "Grècia",
    name_es: "Grecia",
    dial_code: "+30",
    code: "GR"
  },
  {
    name_en: "Greenland",
    name_de: "Grönland",
    name_ca: "Groenlàndia",
    name_es: "Groenlandia",
    dial_code: "+299",
    code: "GL"
  },
  {
    name_en: "Grenada",
    name_de: "Grenada",
    name_ca: "Granada",
    name_es: "Granada",
    dial_code: "+1 473",
    code: "GD"
  },
  {
    name_en: "Guadeloupe",
    name_de: "Guadeloupe",
    name_ca: "Guadalupe",
    name_es: "Guadalupe",
    dial_code: "+590",
    code: "GP"
  },
  {
    name_en: "Guam",
    name_de: "Guam",
    name_ca: "Guam",
    name_es: "Guam",
    dial_code: "+1 671",
    code: "GU"
  },
  {
    name_en: "Guatemala",
    name_de: "Guatemala",
    name_ca: "Guatemala",
    name_es: "Guatemala",
    dial_code: "+502",
    code: "GT"
  },
  {
    name_en: "Guernsey",
    name_de: "Guernsey",
    name_ca: "Guernsey",
    name_es: "Guernsey",
    dial_code: "+44",
    code: "GG"
  },
  {
    name_en: "Guinea",
    name_de: "Guinea",
    name_ca: "Guinea",
    name_es: "Guinea",
    dial_code: "+224",
    code: "GN"
  },
  {
    name_en: "Guinea-Bissau",
    name_de: "Guinea-Bissau",
    name_ca: "Guinea Bisau",
    name_es: "Guinea-Bisau",
    dial_code: "+245",
    code: "GW"
  },
  {
    name_en: "Guyana",
    name_de: "Guyana",
    name_ca: "Guaiana",
    name_es: "Guyana",
    dial_code: "+595",
    code: "GY"
  },
  {
    name_en: "Haiti",
    name_de: "Haiti",
    name_ca: "Haiti",
    name_es: "Haiti",
    dial_code: "+509",
    code: "HT"
  },
  {
    name_en: "Holy See (Vatican City State)",
    name_de: "Heiliger Stuhl (Vatikanstadt)",
    name_ca: "Ciutat de Vaticà",
    name_es: "Ciudad del Vaticano",
    dial_code: "+379",
    code: "VA"
  },
  {
    name_en: "Honduras",
    name_de: "Honduras",
    name_ca: "Hondures",
    name_es: "Honduras",
    dial_code: "+504",
    code: "HN"
  },
  {
    name_en: "Hong Kong",
    name_de: "Hongkong",
    name_ca: "Hong Kong",
    name_es: "Hong Kong",
    dial_code: "+852",
    code: "HK"
  },
  {
    name_en: "Hungary",
    name_de: "Ungarn",
    name_ca: "Hungria",
    name_es: "Hungria",
    dial_code: "+36",
    code: "HU"
  },
  {
    name_en: "Iceland",
    name_de: "Island",
    name_ca: "Islàndia",
    name_es: "Islandia",
    dial_code: "+354",
    code: "IS"
  },
  {
    name_en: "India",
    name_de: "Indien",
    name_ca: "Índia",
    name_es: "India",
    dial_code: "+91",
    code: "IN"
  },
  {
    name_en: "Indonesia",
    name_de: "Indonesien",
    name_ca: "Indonèsia",
    name_es: "Indonesia",
    dial_code: "+62",
    code: "ID"
  },
  {
    name_en: "Iran, Islamic Republic of",
    name_de: "Iran, Islamische Republik",
    name_ca: "Iran",
    name_es: "Iran",
    dial_code: "+98",
    code: "IR"
  },
  {
    name_en: "Iraq",
    name_de: "Irak",
    name_ca: "Iraq",
    name_es: "Iraq",
    dial_code: "+964",
    code: "IQ"
  },
  {
    name_en: "Ireland",
    name_de: "Irland",
    name_ca: "Irlanda",
    name_es: "Irlanda",
    dial_code: "+353",
    code: "IE"
  },
  {
    name_en: "Isle of Man",
    name_de: "Insel Man",
    name_ca: "Illa de Man",
    name_es: "Isla de Man",
    dial_code: "+44",
    code: "IM"
  },
  {
    name_en: "Israel",
    name_de: "Israel",
    name_ca: "Israel",
    name_es: "Israel",
    dial_code: "+972",
    code: "IL"
  },
  {
    name_en: "Italy",
    name_de: "Italien",
    name_ca: "Itàlia",
    name_es: "Italia",
    dial_code: "+39",
    code: "IT"
  },
  {
    name_en: "Jamaica",
    name_de: "Jamaika",
    name_ca: "Jamaica",
    name_es: "Jamaica",
    dial_code: "+1 876",
    code: "JM"
  },
  {
    name_en: "Japan",
    name_de: "Japan",
    name_ca: "Japó",
    name_es: "Japon",
    dial_code: "+81",
    code: "JP"
  },
  {
    name_en: "Jersey",
    name_de: "Jersey",
    name_ca: "Jersey",
    name_es: "Jersey",
    dial_code: "+44",
    code: "JE"
  },
  {
    name_en: "Jordan",
    name_de: "Jordanien",
    name_ca: "Jordània",
    name_es: "Jordania",
    dial_code: "+962",
    code: "JO"
  },
  {
    name_en: "Kazakhstan",
    name_de: "Kasachstan",
    name_ca: "Kazajistan",
    name_es: "Kazajistan",
    dial_code: "+7 7",
    code: "KZ"
  },
  {
    name_en: "Kenya",
    name_de: "Kenia",
    name_ca: "Kenya",
    name_es: "Kenia",
    dial_code: "+254",
    code: "KE"
  },
  {
    name_en: "Kiribati",
    name_de: "Kiribati",
    name_ca: "Kiribati",
    name_es: "Kiribati",
    dial_code: "+686",
    code: "KI"
  },
  {
    name_en: "Korea, Democratic People's Republic of",
    name_de: "Korea, Demokratische Volksrepublik",
    name_ca: "Corea del Nord",
    name_es: "Corea del Norte",
    dial_code: "+850",
    code: "KP"
  },
  {
    name_en: "Korea, Republic of",
    name_de: "Korea, Republik",
    name_ca: "Corea de Sud",
    name_es: "Corea del Sur",
    dial_code: "+82",
    code: "KR"
  },
  {
    name_en: "Kuwait",
    name_de: "Kuwait",
    name_ca: "Kuwait",
    name_es: "Kuwait",
    dial_code: "+965",
    code: "KW"
  },
  {
    name_en: "Kyrgyzstan",
    name_de: "Kirgisistan",
    name_ca: "Kirguistan",
    name_es: "Kirguistan",
    dial_code: "+996",
    code: "KG"
  },
  {
    name_en: "Lao People's Democratic Republic",
    name_de: "Laos, Demokratische Volksrepublik",
    name_ca: "Laos",
    name_es: "Laos",
    dial_code: "+856",
    code: "LA"
  },
  {
    name_en: "Latvia",
    name_de: "Lettland",
    name_ca: "Letònia",
    name_es: "Letonia",
    dial_code: "+371",
    code: "LV"
  },
  {
    name_en: "Lebanon",
    name_de: "Libanon",
    name_ca: "Liban",
    name_es: "Libano",
    dial_code: "+961",
    code: "LB"
  },
  {
    name_en: "Lesotho",
    name_de: "Lesotho",
    name_ca: "Lesotho",
    name_es: "Lesoto",
    dial_code: "+266",
    code: "LS"
  },
  {
    name_en: "Liberia",
    name_de: "Liberia",
    name_ca: "Libèria",
    name_es: "Liberia",
    dial_code: "+231",
    code: "LR"
  },
  {
    name_en: "Libyan Arab Jamahiriya",
    name_de: "Libysch-Arabische Dschamahirija",
    name_ca: "Líbia",
    name_es: "Libia",
    dial_code: "+218",
    code: "LY"
  },
  {
    name_en: "Liechtenstein",
    name_de: "Liechtenstein",
    name_ca: "Liechtenstein",
    name_es: "Liechtenstein",
    dial_code: "+423",
    code: "LI"
  },
  {
    name_en: "Lithuania",
    name_de: "Litauen",
    name_ca: "Lituània",
    name_es: "Lituania",
    dial_code: "+370",
    code: "LT"
  },
  {
    name_en: "Luxembourg",
    name_de: "Luxemburg",
    name_ca: "Luxemburg",
    name_es: "Luxemburgo",
    dial_code: "+352",
    code: "LU"
  },
  {
    name_en: "Macao",
    name_de: "Macao",
    name_ca: "Macau",
    name_es: "Macao",
    dial_code: "+853",
    code: "MO"
  },
  {
    name_en: "Macedonia, The Former Yugoslav Republic of",
    name_de: "Ehemalige jugoslawische Republik Mazedonien",
    name_ca: "República de Macedònia",
    name_es: "Republica de Macedonia",
    dial_code: "+389",
    code: "MK"
  },
  {
    name_en: "Madagascar",
    name_de: "Madagaskar",
    name_ca: "Madagascar",
    name_es: "Madagascar",
    dial_code: "+261",
    code: "MG"
  },
  {
    name_en: "Malawi",
    name_de: "Malawi",
    name_ca: "Malawi",
    name_es: "Malaui",
    dial_code: "+265",
    code: "MW"
  },
  {
    name_en: "Malaysia",
    name_de: "Malaysia",
    name_ca: "Malàisia",
    name_es: "Malasia",
    dial_code: "+60",
    code: "MY"
  },
  {
    name_en: "Maldives",
    name_de: "Malediven",
    name_ca: "Maldives",
    name_es: "Maldivas",
    dial_code: "+960",
    code: "MV"
  },
  {
    name_en: "Mali",
    name_de: "Mali",
    name_ca: "Mali",
    name_es: "Mali",
    dial_code: "+223",
    code: "ML"
  },
  {
    name_en: "Malta",
    name_de: "Malta",
    name_ca: "Malta",
    name_es: "Malta",
    dial_code: "+356",
    code: "MT"
  },
  {
    name_en: "Marshall Islands",
    name_de: "Marshallinseln",
    name_ca: "Illes Marshall",
    name_es: "Islas Marshall",
    dial_code: "+692",
    code: "MH"
  },
  {
    name_en: "Martinique",
    name_de: "Martinique",
    name_ca: "Martinica",
    name_es: "Martinica",
    dial_code: "+596",
    code: "MQ"
  },
  {
    name_en: "Mauritania",
    name_de: "Mauretanien",
    name_ca: "Mauritània",
    name_es: "Mauritania",
    dial_code: "+222",
    code: "MR"
  },
  {
    name_en: "Mauritius",
    name_de: "Mauritius",
    name_ca: "Mauricio",
    name_es: "Mauricio",
    dial_code: "+230",
    code: "MU"
  },
  {
    name_en: "Mayotte",
    name_de: "Mayotte",
    name_ca: "Mayotte",
    name_es: "Mayotte",
    dial_code: "+262",
    code: "YT"
  },
  {
    name_en: "Mexico",
    name_de: "Mexiko",
    name_ca: "Mèxic",
    name_es: "Mexico",
    dial_code: "+52",
    code: "MX"
  },
  {
    name_en: "Micronesia, Federated States of",
    name_de: "Mikronesien, Föderierte Staaten von",
    name_ca: "Estats Federats de Micronèsia",
    name_es: "Estados Federados de Micronesia",
    dial_code: "+691",
    code: "FM"
  },
  {
    name_en: "Moldova, Republic of",
    name_de: "Moldawien, Republik",
    name_ca: "Moldàvia",
    name_es: "Moldavia",
    dial_code: "+373",
    code: "MD"
  },
  {
    name_en: "Monaco",
    name_de: "Monaco",
    name_ca: "Monaco",
    name_es: "Monaco",
    dial_code: "+377",
    code: "MC"
  },
  {
    name_en: "Mongolia",
    name_de: "Mongolei",
    name_ca: "Mongòlia",
    name_es: "Mongolia",
    dial_code: "+976",
    code: "MN"
  },
  {
    name_en: "Montenegro",
    name_de: "Montenegro",
    name_ca: "Montenegro",
    name_es: "Montenegro",
    dial_code: "+382",
    code: "ME"
  },
  {
    name_en: "Montserrat",
    name_de: "Montserrat",
    name_ca: "Montserrat",
    name_es: "Montserrat",
    dial_code: "+1664",
    code: "MS"
  },
  {
    name_en: "Morocco",
    name_de: "Marokko",
    name_ca: "El Marroc",
    name_es: "Marruecos",
    dial_code: "+212",
    code: "MA"
  },
  {
    name_en: "Mozambique",
    name_de: "Mosambik",
    name_ca: "Moçambic",
    name_es: "Mozambique",
    dial_code: "+258",
    code: "MZ"
  },
  {
    name_en: "Myanmar",
    name_de: "Myanmar",
    name_ca: "Birmània",
    name_es: "Birmania",
    dial_code: "+95",
    code: "MM"
  },
  {
    name_en: "Namibia",
    name_de: "Namibia",
    name_ca: "Namíbia",
    name_es: "Namibia",
    dial_code: "+264",
    code: "NA"
  },
  {
    name_en: "Nauru",
    name_de: "Nauru",
    name_ca: "Nauru",
    name_es: "Nauru",
    dial_code: "+674",
    code: "NR"
  },
  {
    name_en: "Nepal",
    name_de: "Nepal",
    name_ca: "Nepal",
    name_es: "Nepal",
    dial_code: "+977",
    code: "NP"
  },
  {
    name_en: "Netherlands",
    name_de: "Niederlande",
    name_ca: "Holanda",
    name_es: "Holanda",
    dial_code: "+31",
    code: "NL"
  },
  {
    name_en: "Netherlands Antilles",
    name_de: "Niederländische Antillen",
    name_ca: "Antilles Holandeses",
    name_es: "Antillas Holandesas",
    dial_code: "+599",
    code: "AN"
  },
  {
    name_en: "New Caledonia",
    name_de: "Neukaledonien",
    name_ca: "Nova Caledònia",
    name_es: "Nueva Caledonia",
    dial_code: "+687",
    code: "NC"
  },
  {
    name_en: "New Zealand",
    name_de: "Neuseeland",
    name_ca: "Nova Zelanda",
    name_es: "Nueva Zelanda",
    dial_code: "+64",
    code: "NZ"
  },
  {
    name_en: "Nicaragua",
    name_de: "Nicaragua",
    name_ca: "Nicaragua",
    name_es: "Nicaragua",
    dial_code: "+505",
    code: "NI"
  },
  {
    name_en: "Niger",
    name_de: "Niger",
    name_ca: "Níger",
    name_es: "Niger",
    dial_code: "+227",
    code: "NE"
  },
  {
    name_en: "Nigeria",
    name_de: "Nigeria",
    name_ca: "Nigèria",
    name_es: "Nigeria",
    dial_code: "+234",
    code: "NG"
  },
  {
    name_en: "Niue",
    name_de: "Niue",
    name_ca: "Niue",
    name_es: "Niue",
    dial_code: "+683",
    code: "NU"
  },
  {
    name_en: "Norfolk Island",
    name_de: "Norfolkinsel",
    name_ca: "Illa Norfolk",
    name_es: "Isla Norfolk",
    dial_code: "+672",
    code: "NF"
  },
  {
    name_en: "Northern Mariana Islands",
    name_de: "Nördliche Marianen",
    name_ca: "Illes Marianes de el Nord",
    name_es: "Islas Marianas del Norte",
    dial_code: "+1670",
    code: "MP"
  },
  {
    name_en: "Norway",
    name_de: "Norwegen",
    name_ca: "Noruega",
    name_es: "Noruega",
    dial_code: "+47",
    code: "NO"
  },
  {
    name_en: "Oman",
    name_de: "Oman",
    name_ca: "Oman",
    name_es: "Oman",
    dial_code: "+968",
    code: "OM"
  },
  {
    name_en: "Pakistan",
    name_de: "Pakistan",
    name_ca: "Pakistan",
    name_es: "Pakistan",
    dial_code: "+92",
    code: "PK"
  },
  {
    name_en: "Palau",
    name_de: "Palau",
    name_ca: "Palaos",
    name_es: "Palaos",
    dial_code: "+680",
    code: "PW"
  },
  {
    name_en: "Palestinian Territory, Occupied",
    name_de: "Palästinensische Gebiete, besetzt",
    name_ca: "Territoris Palestins",
    name_es: "Territorios Palestinos",
    dial_code: "+970",
    code: "PS"
  },
  {
    name_en: "Panama",
    name_de: "Panama",
    name_ca: "Panama",
    name_es: "Panama",
    dial_code: "+507",
    code: "PA"
  },
  {
    name_en: "Papua New Guinea",
    name_de: "Papua-Neuguinea",
    name_ca: "Papua Nova Guinea",
    name_es: "Papua Nueva Guinea",
    dial_code: "+675",
    code: "PG"
  },
  {
    name_en: "Paraguay",
    name_de: "Paraguay",
    name_ca: "Paraguai",
    name_es: "Paraguay",
    dial_code: "+595",
    code: "PY"
  },
  {
    name_en: "Peru",
    name_de: "Peru",
    name_ca: "Peru",
    name_es: "Peru",
    dial_code: "+51",
    code: "PE"
  },
  {
    name_en: "Philippines",
    name_de: "Philippinen",
    name_ca: "Filipines",
    name_es: "Filipinas",
    dial_code: "+63",
    code: "PH"
  },
  {
    name_en: "Pitcairn",
    name_de: "Pitcairninseln",
    name_ca: "Illes Pitcairn",
    name_es: "Islas Pitcairn",
    dial_code: "+872",
    code: "PN"
  },
  {
    name_en: "Poland",
    name_de: "Polen",
    name_ca: "Polònia",
    name_es: "Polonia",
    dial_code: "+48",
    code: "PL"
  },
  {
    name_en: "Portugal",
    name_de: "Portugal",
    name_ca: "Portugal",
    name_es: "Portugal",
    dial_code: "+351",
    code: "PT"
  },
  {
    name_en: "Puerto Rico",
    name_de: "Puerto Rico",
    name_ca: "Puerto Rico",
    name_es: "Puerto Rico",
    dial_code: "+1 939",
    code: "PR"
  },
  {
    name_en: "Qatar",
    name_de: "Katar",
    name_ca: "Qatar",
    name_es: "Qatar",
    dial_code: "+974",
    code: "QA"
  },
  {
    name_en: "Romania",
    name_de: "Rumänien",
    name_ca: "Romania",
    name_es: "Rumania",
    dial_code: "+40",
    code: "RO"
  },
  {
    name_en: "Russia",
    name_de: "Russische Föderation",
    name_ca: "Rússia",
    name_es: "Rusia",
    dial_code: "+7",
    code: "RU"
  },
  {
    name_en: "Rwanda",
    name_de: "Ruanda",
    name_ca: "Rwanda",
    name_es: "Ruanda",
    dial_code: "+250",
    code: "RW"
  },
  {
    name_en: "Réunion",
    name_de: "Reunion",
    name_ca: "Reunion",
    name_es: "Reunion",
    dial_code: "+262",
    code: "RE"
  },
  {
    name_en: "Saint Barthélemy",
    name_de: "Saint Barthélemy",
    name_ca: "San Bartolome",
    name_es: "San Bartolome",
    dial_code: "+590",
    code: "BL"
  },
  {
    name_en: "Saint Helena, Ascension and Tristan Da Cunha",
    name_de: "Saint Helena, Ascension und Tristan da Cunha",
    name_ca: "Santa Elena, Ascensió i Tristan da Cunha",
    name_es: "Santa Elena, Ascensión y Tristán de Acuña",
    dial_code: "+290",
    code: "SH"
  },
  {
    name_en: "Saint Kitts and Nevis",
    name_de: "Saint Kitts und Nevis",
    name_ca: "San Cristóbal i Neus",
    name_es: "San Cristóbal y Nieves",
    dial_code: "+1 869",
    code: "KN"
  },
  {
    name_en: "Saint Lucia",
    name_de: "Saint Lucia",
    name_ca: "Santa Lucia",
    name_es: "Santa Lucia",
    dial_code: "+1 758",
    code: "LC"
  },
  {
    name_en: "Saint Martin",
    name_de: "Saint Martin",
    name_ca: "Illa de Sant Martín",
    name_es: "Isla de San Martín",
    dial_code: "+590",
    code: "MF"
  },
  {
    name_en: "Saint Pierre and Miquelon",
    name_de: "Saint Pierre und Miquelon",
    name_ca: "Sant Pere i Miquelon",
    name_es: "San Pedro y Miquelon",
    dial_code: "+508",
    code: "PM"
  },
  {
    name_en: "Saint Vincent and the Grenadines",
    name_de: "Saint Vincent und die Grenadinen",
    name_ca: "Saint Vincent i les Grenadines",
    name_es: "San Vicente y las Granadinas",
    dial_code: "+1 784",
    code: "VC"
  },
  {
    name_en: "Samoa",
    name_de: "Samoa",
    name_ca: "Samoa",
    name_es: "Samoa",
    dial_code: "+685",
    code: "WS"
  },
  {
    name_en: "San Marino",
    name_de: "San Marino",
    name_ca: "San Marino",
    name_es: "San Marino",
    dial_code: "+378",
    code: "SM"
  },
  {
    name_en: "Sao Tome and Principe",
    name_de: "Sao Tome und Principe",
    name_ca: "Sant Prengui i Principe",
    name_es: " Santo Tome y Principe ",
    dial_code: "+239",
    code: "ST"
  },
  {
    name_en: "Saudi Arabia",
    name_de: "Saudi-Arabien",
    name_ca: "Aràbia Saudita",
    name_es: "Arabia Saudita",
    dial_code: "+966",
    code: "SA"
  },
  {
    name_en: "Senegal",
    name_de: "Senegal",
    name_ca: "Senegal",
    name_es: "Senegal",
    dial_code: "+221",
    code: "SN"
  },
  {
    name_en: "Serbia",
    name_de: "Serbien",
    name_ca: "Sèrbia",
    name_es: "Serbia",
    dial_code: "+381",
    code: "RS"
  },
  {
    name_en: "Seychelles",
    name_de: "Seychellen",
    name_ca: "Seychelles",
    name_es: "Seychelles",
    dial_code: "+248",
    code: "SC"
  },
  {
    name_en: "Sierra Leone",
    name_de: "Sierra Leone",
    name_ca: "Sierra Leone",
    name_es: "Sierra Leona",
    dial_code: "+232",
    code: "SL"
  },
  {
    name_en: "Singapore",
    name_de: "Singapur",
    name_ca: "Singapur",
    name_es: "Singapur",
    dial_code: "+65",
    code: "SG"
  },
  {
    name_en: "Slovakia",
    name_de: "Slowakei",
    name_ca: "Eslovàquia",
    name_es: "Eslovaquia",
    dial_code: "+421",
    code: "SK"
  },
  {
    name_en: "Slovenia",
    name_de: "Slowenien",
    name_ca: "Eslovènia",
    name_es: "Eslovenia",
    dial_code: "+386",
    code: "SI"
  },
  {
    name_en: "Solomon Islands",
    name_de: "Salomonen",
    name_ca: "Illes Salomó",
    name_es: "Islas Salomón",
    dial_code: "+677",
    code: "SB"
  },
  {
    name_en: "Somalia",
    name_de: "Somalia",
    name_ca: "Somàlia",
    name_es: "Somalia",
    dial_code: "+252",
    code: "SO"
  },
  {
    name_en: "South Africa",
    name_de: "Südafrika",
    name_ca: "Sud-àfrica",
    name_es: "Sudáfrica",
    dial_code: "+27",
    code: "ZA"
  },
  {
    name_en: "South Georgia and the South Sandwich Islands",
    name_de: "Südgeorgien und die Südlichen Sandwichinseln",
    name_ca: "Illes Georgias de Sud i Sandvitx de Sud",
    name_es: "Islas Georgias del Sur y Sandwich del Sur",
    dial_code: "+500",
    code: "GS"
  },
  {
    name_en: "Spain",
    name_de: "Spanien",
    name_ca: "Espanya",
    name_es: "España",
    dial_code: "+34",
    code: "ES"
  },
  {
    name_en: "Sri Lanka",
    name_de: "Sri Lanka",
    name_ca: "Sri Lanka",
    name_es: "Sri Lanka",
    dial_code: "+94",
    code: "LK"
  },
  {
    name_en: "Sudan",
    name_de: "Sudan",
    name_ca: "Sudan",
    name_es: "Sudan",
    dial_code: "+249",
    code: "SD"
  },
  {
    name_en: "Suriname",
    name_de: "Suriname",
    name_ca: "Surinam",
    name_es: "Surinam ",
    dial_code: "+597",
    code: "SR"
  },
  {
    name_en: "Svalbard and Jan Mayen",
    name_de: "Svalbard und Jan Mayen",
    name_ca: "Svalbard i Jan Mayen",
    name_es: "Svalbard y Jan Mayen",
    dial_code: "+47",
    code: "SJ"
  },
  {
    name_en: "Swaziland",
    name_de: "Swasiland",
    name_ca: "Swazilàndia",
    name_es: "Suazilandia",
    dial_code: "+268",
    code: "SZ"
  },
  {
    name_en: "Sweden",
    name_de: "Schweden",
    name_ca: "Suècia",
    name_es: "Suecia",
    dial_code: "+46",
    code: "SE"
  },
  {
    name_en: "Switzerland",
    name_de: "Schweiz",
    name_ca: "Suïssa",
    name_es: "Suiza",
    dial_code: "+41",
    code: "CH"
  },
  {
    name_en: "Syrian Arab Republic",
    name_de: "Syrische Arabische Republik",
    name_ca: "Síria",
    name_es: "Siria",
    dial_code: "+963",
    code: "SY"
  },
  {
    name_en: "Taiwan, Province of China",
    name_de: "Taiwan, Provinz China",
    name_ca: "Taiwan",
    name_es: "Taiwan",
    dial_code: "+886",
    code: "TW"
  },
  {
    name_en: "Tajikistan",
    name_de: "Tadschikistan",
    name_ca: "Tayikistan",
    name_es: "Tayikistan",
    dial_code: "+992",
    code: "TJ"
  },
  {
    name_en: "Tanzania, United Republic of",
    name_de: "Tansania, Vereinigte Republik",
    name_ca: "Tanzània",
    name_es: "Tanzania",
    dial_code: "+255",
    code: "TZ"
  },
  {
    name_en: "Thailand",
    name_de: "Thailand",
    name_ca: "Tailàndia",
    name_es: "Tailandia",
    dial_code: "+66",
    code: "TH"
  },
  {
    name_en: "Timor-Leste",
    name_de: "Osttimor",
    name_ca: "Timor Oriental",
    name_es: "Timor Oriental",
    dial_code: "+670",
    code: "TL"
  },
  {
    name_en: "Togo",
    name_de: "Togo",
    name_ca: "Togo",
    name_es: "Togo",
    dial_code: "+228",
    code: "TG"
  },
  {
    name_en: "Tokelau",
    name_de: "Tokelau",
    name_ca: "Tokelau",
    name_es: "Tokelau",
    dial_code: "+690",
    code: "TK"
  },
  {
    name_en: "Tonga",
    name_de: "Tonga",
    name_ca: "Tonga",
    name_es: "Tonga",
    dial_code: "+676",
    code: "TO"
  },
  {
    name_en: "Trinidad and Tobago",
    name_de: "Trinidad und Tobago",
    name_ca: "Trinitat i Tobago",
    name_es: "Trinidad y Tobago",
    dial_code: "+1 868",
    code: "TT"
  },
  {
    name_en: "Tunisia",
    name_de: "Tunesien",
    name_ca: "Tunísia",
    name_es: "Tunez",
    dial_code: "+216",
    code: "TN"
  },
  {
    name_en: "Turkey",
    name_de: "Türkei",
    name_ca: "Turquia",
    name_es: "Turquia",
    dial_code: "+90",
    code: "TR"
  },
  {
    name_en: "Turkmenistan",
    name_de: "Turkmenistan",
    name_ca: "Turkmenistan",
    name_es: "Turkmenistan",
    dial_code: "+993",
    code: "TM"
  },
  {
    name_en: "Turks and Caicos Islands",
    name_de: "Turks- und Caicosinseln",
    name_ca: "Illes Turks i Caicos",
    name_es: "Islas Turcas y Caicos",
    dial_code: "+1 649",
    code: "TC"
  },
  {
    name_en: "Tuvalu",
    name_de: "Tuvalu",
    name_ca: "Tuvalu",
    name_es: "Tuvalu",
    dial_code: "+688",
    code: "TV"
  },
  {
    name_en: "Uganda",
    name_de: "Uganda",
    name_ca: "Uganda",
    name_es: "Uganda",
    dial_code: "+256",
    code: "UG"
  },
  {
    name_en: "Ukraine",
    name_de: "Ukraine",
    name_ca: "Ucraïna",
    name_es: "Ucrania",
    dial_code: "+380",
    code: "UA"
  },
  {
    name_en: "United Arab Emirates",
    name_de: "Vereinigte Arabische Emirate",
    name_ca: "Emirats Àrabs Units",
    name_es: "Emiratos Arabes Unidos",
    dial_code: "+971",
    code: "AE"
  },
  {
    name_en: "United Kingdom",
    name_de: "Vereinigtes Königreich",
    name_ca: "Regne Unit",
    name_es: "Reino Unido",
    dial_code: "+44",
    code: "GB"
  },
  {
    name_en: "United States",
    name_de: "Vereinigte Staaten",
    name_ca: "Estats Units",
    name_es: "Estados Unidos",
    dial_code: "+1",
    code: "US"
  },
  {
    name_en: "Uruguay",
    name_de: "Uruguay",
    name_ca: "Uruguai",
    name_es: "Uruguay",
    dial_code: "+598",
    code: "UY"
  },
  {
    name_en: "Uzbekistan",
    name_de: "Usbekistan",
    name_ca: "Uzbekistan",
    name_es: "Uzbekistan",
    dial_code: "+998",
    code: "UZ"
  },
  {
    name_en: "Vanuatu",
    name_de: "Vanuatu",
    name_ca: "Vanuatu",
    name_es: "Vanuatu",
    dial_code: "+678",
    code: "VU"
  },
  {
    name_en: "Venezuela, Bolivarian Republic of",
    name_de: "Venezuela, Bolivarische Republik",
    name_ca: "Veneçuela",
    name_es: "Venezuela",
    dial_code: "+58",
    code: "VE"
  },
  {
    name_en: "Viet Nam",
    name_de: "Vietnam",
    name_ca: "Vietnam",
    name_es: "Viet Nam",
    dial_code: "+84",
    code: "VN"
  },
  {
    name_en: "Virgin Islands, British",
    name_de: "Jungferninseln, Britische",
    name_ca: "Illes Verges Britàniques",
    name_es: "Islas Vírgenes Británicas",
    dial_code: "+1 284",
    code: "VG"
  },
  {
    name_en: "Virgin Islands, U.S.",
    name_de: "Jungferninseln, US-amerikanische",
    name_ca: "Illes Verges dels Estats Units",
    name_es: "Islas Vírgenes de los Estados Unidos",
    dial_code: "+1 340",
    code: "VI"
  },
  {
    name_en: "Wallis and Futuna",
    name_de: "Wallis und Futuna",
    name_ca: "Wallis i Futuna",
    name_es: "Wallis y Futuna",
    dial_code: "+681",
    code: "WF"
  },
  {
    name_en: "Yemen",
    name_de: "Jemen",
    name_ca: "Iemen",
    name_es: "Yemen",
    dial_code: "+967",
    code: "YE"
  },
  {
    name_en: "Zambia",
    name_de: "Sambia",
    name_ca: "Zàmbia",
    name_es: "Zambia",
    dial_code: "+260",
    code: "ZM"
  },
  {
    name_en: "Zimbabwe",
    name_de: "Simbabwe",
    name_ca: "Zimbabwe",
    name_es: " Zimbabue",
    dial_code: "+263",
    code: "ZW"
  },
  {
    name_en: "Åland Islands",
    name_de: "Ålandinseln",
    name_ca: "Åland",
    name_es: "Åland",
    dial_code: "+358",
    code: "AX"
  }
];
