import Vue from "vue";
import VueRouter from "vue-router";
import { canNavigate } from "@/libs/acl/routeProtection";
import store from "@/store";
import useBasicAuth from "@/auth/basic/useBasicAuth";

// Foravila Routes
import security from "./foravila/security";
import home from "./foravila/home";
import bookings from "./foravila/bookings";
import reviews from "./foravila/reviews";
import services from "./foravila/services";
import booking from "./foravila/booking";
import account from "./foravila/account";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    // Foravila routes
    ...security,
    ...home,
    ...bookings,
    ...reviews,
    ...services,
    ...booking,
    ...account,

    // Default/Error route
    {
      path: "*",
      redirect: "not-found"
    }
  ]
});

router.beforeEach((to, _, next) => {
  const userData = JSON.parse(localStorage.getItem("foravilaGuestsUserData"));

  // These are the route names when the beforeEach hook should not redirect to foravila-set-password route
  const noRedirectRoutes = ["foravila-logout", "foravila-set-password"];

  if (
    userData &&
    !userData.passwordUpdatedAt &&
    !noRedirectRoutes.includes(to.name)
  ) {
    // Make sure that the user has set a custom password,
    // otherwise redirect to set password page
    next({ name: "foravila-set-password" });
  } else if (to?.meta?.mustBeLoggedOut) {
    // If the user must be logged out to access to the route,
    // log out and continue to the route
    useBasicAuth.logout();
    useBasicAuth.removeUser();
    next();
  } else {
    // In the rest of the routes, check if the user is logged in,
    // otherwhise redirect to the login page
    useBasicAuth
      .checkLoggedIn()
      .then(loggedIn => {
        if (!loggedIn) next({ name: "foravila-login" });
        else if (!canNavigate(to)) next({ name: "not-authorized" });
        else next();
      })
      .catch(() => next({ name: "foravila-login" }));
  }
});

// ? For splash screen
router.afterEach(to => {
  // For autologin, don't remove the splash screen
  if (to.name === "foravila-login" && to.query?.e && to.query?.p) return;
  // Remove the splash screen
  store.dispatch("app/setAppLoading", false);
});

export default router;
