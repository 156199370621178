export default [
  {
    path: '/login',
    name: 'foravila-login',
    component: () => import('@/views/foravila/security/Login.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      mustBeLoggedOut: true,
    },
  },
  {
    path: '/logout',
    name: 'foravila-logout',
    component: () => import('@/views/foravila/security/Logout.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
    },
  },
  {
    path: '/forgot-password',
    name: 'foravila-forgot-password',
    component: () => import('@/views/foravila/security/ForgotPassword.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      mustBeLoggedOut: true,
    },
  },
  {
    path: '/check-email',
    name: 'foravila-check-email',
    component: () => import('@/views/foravila/security/CheckEmail.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      mustBeLoggedOut: true,
    },
  },
  {
    path: '/reset-password/:token?',
    name: 'foravila-reset-password',
    component: () => import('@/views/foravila/security/ResetPassword.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      mustBeLoggedOut: true,
    },
  },
  {
    path: '/set-password',
    name: 'foravila-set-password',
    component: () => import('@/views/foravila/security/SetPassword.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
    },
  },
  {
    path: '/online-checkin/:token?',
    name: 'foravila-register',
    component: () => import('@/views/foravila/security/Register.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      mustBeLoggedOut: true,
    },
  },
  {
    path: '/not-authorized',
    name: 'not-authorized',
    component: () => import('@/views/foravila/security/NotAuthorized.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
    },
  },
  {
    path: '/not-found',
    name: 'not-found',
    component: () => import('@/views/foravila/security/NotFound.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
    },
  },
]
