export default [
  //    - Booking details
  {
    path: '/reviews',
    name: 'foravila-reviews',
    component: () => import('@/views/foravila/reviews/ReviewsList.vue'),
    meta: {
      pageTitle: 'Llistat de ressenyes',
      breadcrumb: [
        { text: 'Inici' },
        { text: 'Reviews', active: true },
      ],
    },
  },
]
