import axios from '@/axios'

export default {
  namespaced: true,
  state: {
    loading: false,
    client: null,
  },
  getters: {
    loading(state) {
      return state.loading
    },
    client(state) {
      return state.client
    },
    location(state) {
      if (!state.client?.location) return null
      return state.client.location
    },
  },
  mutations: {
    RESET(state) {
      state.loading = false
      state.client = null
    },
    SET_LOADING(state, payload) {
      state.loading = payload
    },
    SET_CLIENT(state, payload) {
      state.client = payload
    },
    SET_CLIENT_LOCATION(state, payload) {
      if (!state.client) return
      state.client.location = payload
    },
  },
  actions: {
    reset({ commit }) {
      commit('RESET')
    },
    fetchClient({ commit }, clientUuid) {
      if (!clientUuid) return null
      return new Promise((resolve, reject) => {
        commit('SET_LOADING', true)
        axios
          .get(`/clients/${clientUuid}`)
          .then(response => {
            commit('SET_CLIENT', response.data)
            resolve()
          })
          .catch(error => {
            // TODO: log the error with Sentry
            reject(error)
          })
          .finally(() => commit('SET_LOADING', false))
      })
    },
    updateClient({ commit }, client) {
      if (!client || !client.uuid) return null
      return new Promise((resolve, reject) => {
        axios
          .patch(`/clients/${client.uuid}`, client, {
            headers: {
              'Content-Type': 'application/merge-patch+json',
            },
          })
          .then(response => {
            if (response.status === 200) {
              commit('SET_CLIENT', response.data)
              resolve(response.data)
            } else {
              reject(new Error('The request was successfull, but the received status was not the expected one'))
            }
          })
          .catch(error => {
            // TODO: log the error with Sentry
            reject(error)
          })
      })
    },
    updateLocation({ commit }, location) {
      if (!location || !location.uuid) return null
      return new Promise((resolve, reject) => {
        axios
          .patch(`/locations/${location.uuid}`, location, {
            headers: {
              'Content-Type': 'application/merge-patch+json',
            },
          })
          .then(response => {
            if (response.status === 200) {
              commit('SET_CLIENT_LOCATION', response.data)
              resolve(response.data)
            } else {
              reject(new Error('The request was successfull, but the received status was not the expected one'))
            }
          })
          .catch(error => {
            // TODO: log the error with Sentry
            reject(error)
          })
      })
    },
  },
}
