import axios from "@/axios";
import { UNCALCULABLE_FREQUENCIES } from "@/utils/consts";
import { getBookingServiceClientTotalPrice } from "@/utils/methods";

export default {
  namespaced: true,
  state: {
    loadingBooking: false,
    booking: null,
    loadingClientPayments: false,
    clientPayments: [],
    loadingGuests: false,
    guests: [],
    loadingAccommodationServices: false,
    accommodationServices: []
  },
  getters: {
    loading(state) {
      return (
        state.loadingBooking ||
        state.loadingClientPayments ||
        state.loadingGuests ||
        state.loadingAccommodationServices
      );
    },
    loadingBooking(state) {
      return state.loadingBooking;
    },
    booking(state) {
      return state.booking;
    },
    accommodation(state, getters) {
      return getters.booking?.accommodation || null;
    },
    discount(satate, getters) {
      return getters.booking?.discount || null;
    },
    discountPrice(satate, getters) {
      return getters.booking?.discountPrice || 0;
    },
    rates(state, getters) {
      return getters.booking?.rates || [];
    },
    services(state, getters) {
      return getters.booking?.services || [];
    },
    requirements(state, getters) {
      if (!getters.services?.length) {
        return [];
      }

      return getters.services.filter(
        bookingService => bookingService.service?.type === "REQUIREMENT"
      );
    },
    hiredAccommodationExtras(state, getters) {
      if (!getters.services?.length) {
        return [];
      }

      return getters.services.filter(
        bookingService => bookingService.service?.type === "ACCOMMODATION_EXTRA"
      );
    },
    hiredThirdPartyServices(state, getters) {
      if (!getters.services?.length) {
        return [];
      }

      return getters.services.filter(
        bookingService => bookingService.service?.type === "3RD_PARTY_SERVICE"
      );
    },
    subtotal(state, getters) {
      return getters.booking?.ratesPrice || 0;
    },
    subtotalDiscounted(state, getters) {
      const { subtotal, discountPrice } = getters;
      return subtotal - discountPrice;
    },
    nights(state, getters) {
      return getters.booking?.nights || null;
    },
    averageRatePerNight(state, getters) {
      const { subtotalDiscounted, nights } = getters;

      if (!subtotalDiscounted || !nights) return null;
      return subtotalDiscounted / getters.nights;
    },
    total(state, getters) {
      const { subtotalDiscounted, chargableServicesTotal } = getters;

      let total = 0;

      if (subtotalDiscounted > 0) {
        total += subtotalDiscounted;
      }
      if (chargableServicesTotal > 0) {
        total += chargableServicesTotal;
      }

      return total;
    },
    chargableServices(state, getters) {
      if (!getters.services.length) return [];
      return getters.services.filter(s => {
        // If the payment frequency depends on consumption, the total price is uncalculabe,
        // that means that the service is not chargable
        if (
          s.paymentFrequency &&
          UNCALCULABLE_FREQUENCIES.includes(s.paymentFrequency)
        )
          return false;

        // If the booking service doesn't have a service related (that shouldn't happen),
        // only check if the booking service is chargable
        if (!s.service) return s.chargable;

        // If the booking service have a related service, check if the booking service is chargable
        // and that the related service is not the security deposit
        return s.chargable && s.service.code !== "SECURITY_DEPOSIT";
      });
    },
    chargableServicesTotal(state, getters) {
      if (!getters.chargableServices.length) return 0;
      return getters.chargableServices.reduce((acc, chargableService) => {
        const servicePrice = getBookingServiceClientTotalPrice(
          chargableService,
          state.booking?.nights
        );
        return acc + (servicePrice || 0);
      }, 0);
    },
    unchargableServices(state, getters) {
      if (!getters.services.length) return [];
      return getters.services.filter(s => {
        // If the payment frequency depends on consumption, the total price is uncalculabe,
        // that means that the service is unchargable
        if (
          s.paymentFrequency &&
          UNCALCULABLE_FREQUENCIES.includes(s.paymentFrequency)
        )
          return true;

        // If the booking service doesn't have a service related (that shouldn't happen),
        // only check if the booking service is not chargable
        if (!s.service) return !s.chargable;

        // If the booking service have a service related, check if the booking service is not chargable
        // and that the related service is not the security deposit
        return !s.chargable && s.service.code !== "SECURITY_DEPOSIT";
      });
    },
    loadingClientPayments(state) {
      return state.loadingClientPayments;
    },
    clientPayments(state) {
      if (!state.clientPayments?.length) {
        return [];
      }

      return state.clientPayments.filter(
        clientPayment => clientPayment.pvpAmount >= 0
      );
    },
    clientRefunds(state) {
      if (!state.clientPayments?.length) {
        return [];
      }

      return state.clientPayments.filter(
        clientPayment => clientPayment.pvpAmount < 0
      );
    },
    paid(state, getters) {
      const { clientPayments } = getters;

      if (!clientPayments?.length) {
        return 0;
      }

      return clientPayments.reduce((acc, payment) => {
        if (
          ["ACCOUNTED", "VERIFIED", "CONFIRMED", "PRE_CONFIRMED"].includes(
            payment.status
          ) &&
          payment.scope !== "SECURITY_DEPOSIT"
        ) {
          return acc + (payment.pvpAmount || 0);
        }

        return acc;
      }, 0);
    },
    refunded(state, getters) {
      const { clientRefunds } = getters;

      if (!clientRefunds?.length) {
        return 0;
      }

      const refundedSum = clientRefunds.reduce((acc, payment) => {
        if (
          ["ACCOUNTED", "VERIFIED", "CONFIRMED", "PRE_CONFIRMED"].includes(
            payment.status
          ) &&
          payment.scope !== "SECURITY_DEPOSIT"
        ) {
          return acc + (payment.pvpAmount || 0);
        }
        return acc;
      }, 0);

      return -refundedSum;
    },
    pending(state, getters) {
      return getters.total - getters.paid + getters.refunded;
    },
    securityDeposit(state, getters) {
      const { services } = getters;

      const securityDepositService = services.find(bookingService => {
        const { code } = bookingService.service || {};
        return code && code.includes("SECURITY_DEPOSIT");
      });

      return securityDepositService || null;
    },
    hasSecurityDeposit(state, getters) {
      // TODO: Could it be improved just returning the truthy value of securityDeposit?
      const { services } = getters;

      return services.some(bookingService => {
        const { code } = bookingService.service || {};
        return code && code.includes("SECURITY_DEPOSIT");
      });
    },
    hasChargableSecurityDeposit(state, getters) {
      return getters.securityDeposit?.chargable || false;
    },
    hasManagedSecurityDeposit(state, getters) {
      return getters.booking?.managedSecurityDeposit || false;
    },
    securityDepositPrice(state, getters) {
      return getters.securityDeposit?.pvpPrice || null;
    },
    securityDepositPayments(state, getters) {
      const { clientPayments, securityDeposit } = getters;

      if (!clientPayments.length || !securityDeposit) {
        return [];
      }

      return clientPayments.filter(clientPayment => {
        if (!clientPayment.bookingService) {
          return false;
        }

        // The clientPayment.bookingService attribute comming from the API can be either a string representing the
        // BookingService URI or an object where the @id property represents the BookingService URI
        const bookingServiceUri =
          typeof clientPayment.bookingService === "string"
            ? clientPayment.bookingService
            : clientPayment.bookingService["@id"];

        return bookingServiceUri === securityDeposit["@id"];
      });
    },
    securityDepositRefunds(state, getters) {
      const { clientRefunds, securityDeposit } = getters;

      if (!clientRefunds.length || !securityDeposit) {
        return [];
      }

      return clientRefunds.filter(clientPayment => {
        if (!clientPayment.bookingService) return false;

        // The clientPayment.bookingService attribute comming from the API can be either a string representing the
        // BookingService URI or an object where the @id property represents the BookingService URI
        const bookingServiceUri =
          typeof clientPayment.bookingService === "string"
            ? clientPayment.bookingService
            : clientPayment.bookingService["@id"];

        return bookingServiceUri === securityDeposit["@id"];
      });
    },
    securityDepositChargedPrice(state, getters) {
      const { securityDepositPayments } = getters;

      if (!securityDepositPayments.length) {
        return 0;
      }

      return securityDepositPayments.reduce((acc, clientPayment) => {
        if (
          ["ACCOUNTED", "VERIFIED", "CONFIRMED", "PRE_CONFIRMED"].includes(
            clientPayment.status
          )
        ) {
          return acc + (clientPayment.pvpAmount || 0);
        }
        return acc;
      }, 0);
    },
    securityDepositRefundedPrice(state, getters) {
      const { securityDepositRefunds } = getters;

      if (!securityDepositRefunds.length) {
        return 0;
      }

      const total = securityDepositRefunds.reduce((acc, payment) => {
        if (
          ["ACCOUNTED", "VERIFIED", "CONFIRMED", "PRE_CONFIRMED"].includes(
            payment.status
          )
        ) {
          return acc + (payment.pvpAmount || 0);
        }
        return acc;
      }, 0);

      // As the sum of refunds will be negative, we want to return a positive amount
      // as a refunded price.
      return total ? -total : 0;
    },
    securityDepositPendingPrice(state, getters) {
      const {
        securityDepositPrice,
        securityDepositChargedPrice,
        securityDepositRefundedPrice
      } = getters;

      if (!securityDepositPrice) {
        return null;
      }

      return (
        securityDepositPrice -
        securityDepositChargedPrice +
        securityDepositRefundedPrice
      );
    },
    hasManagedPayment(state, getters) {
      return getters.booking?.managedPayment || false;
    },
    finalPaymentDueDate(state, getters) {
      if (!state.booking?.date) {
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        return today;
      }

      if (!getters.policy) {
        const bookingDateArray = state.booking.date.split("T")[0].split("-");
        const dueDate = new Date(
          parseInt(bookingDateArray[0], 10),
          parseInt(bookingDateArray[1] - 1, 10),
          parseInt(bookingDateArray[2], 10)
        );
        dueDate.setDate(dueDate.getDate() + 1);
        return dueDate;
      }

      const checkinDateArray = state.booking.checkin.split("T")[0].split("-");
      const dueDate = new Date(
        parseInt(checkinDateArray[0], 10),
        parseInt(checkinDateArray[1] - 1, 10),
        parseInt(checkinDateArray[2], 10)
      );
      dueDate.setDate(
        dueDate.getDate() - getters.policy.finalPaymentLimit / 24
      );
      return dueDate;
    },
    policy(state) {
      return state.booking?.policy || null;
    },
    isLastMinute(state, getters) {
      if (!getters.policy || !getters.policy?.lastMinuteStart) return false;
      const checkinDate = state.booking.checkin.split("T")[0];
      const bookingDate = state.booking.date.split("T")[0];
      const hoursBetweenBookingAndCheckin =
        Math.abs(new Date(checkinDate) - new Date(bookingDate)) / 36e5;
      return (
        hoursBetweenBookingAndCheckin <= state.booking.policy.lastMinuteStart
      );
    },
    loadingGuests(state) {
      return state.loadingGuests;
    },
    guests(state) {
      return state.guests;
    },
    details(state) {
      return state.booking?.details || null;
    },
    stats(state) {
      return state.booking?.stats || null;
    },
    review(state) {
      return state.booking?.review || null;
    },
    clientOnlineCheckinCompleted(state, getters) {
      if (!getters.stats) return false;
      return getters.stats.clientOnlineCheckinCompleted;
    },
    guestsOnlineCheckinCompleted(state, getters) {
      if (!getters.stats) return false;
      return getters.stats.guestsOnlineCheckinCompleted;
    },
    arrivalOnlineCheckinCompleted(state, getters) {
      if (!getters.stats) return false;
      return getters.stats.arrivalOnlineCheckinCompleted;
    },
    onlineCheckinCompleted(state, getters) {
      if (!getters.stats) return false;
      return getters.stats.onlineCheckinCompleted;
    },
    loadingAccommodationServices(state) {
      return state.loadingAccommodationServices;
    },
    accommodationServices(state) {
      return state.accommodationServices;
    },
    optionalAccommodationThirdPartyServices(
      state,
      getters,
      rootState,
      rootGetters
    ) {
      if (
        !getters.accommodationServices.length &&
        !rootGetters["services/globalServices"].length
      ) {
        return [];
      }

      const accommodationServices = getters.accommodationServices.filter(
        accommodationService =>
          !accommodationService.mandatory &&
          accommodationService.service?.type === "3RD_PARTY_SERVICE"
      );

      const globalServices = rootGetters["services/globalServices"].filter(
        globalService =>
          !globalService.mandatory &&
          globalService.service?.type === "3RD_PARTY_SERVICE"
      );

      const mergedServices = [...accommodationServices, ...globalServices];

      const mergedServicesCodes = new Set(
        mergedServices.map(mergedService => mergedService.service?.code)
      );

      // Convert the Set back to an array of unique services
      const uniqueServices = Array.from(mergedServicesCodes).map(code => {
        return mergedServices.find(
          mergedService => mergedService.service?.code === code
        );
      });

      return uniqueServices;
    },
    optionalAccommodationExtras(state, getters, rootState, rootGetters) {
      if (
        !getters.accommodationServices.length &&
        !rootGetters["services/globalServices"].length
      ) {
        return [];
      }

      const accommodationServices = getters.accommodationServices.filter(
        accommodationService =>
          !accommodationService.mandatory &&
          accommodationService.service?.type === "ACCOMMODATION_EXTRA"
      );

      const globalServices = rootGetters["services/globalServices"].filter(
        globalService =>
          !globalService.mandatory &&
          globalService.service?.type === "ACCOMMODATION_EXTRA"
      );

      const mergedServices = [...accommodationServices, ...globalServices];

      const mergedServicesCodes = new Set(
        mergedServices.map(mergedService => mergedService.service?.code)
      );

      // Convert the Set back to an array of unique services
      const uniqueServices = Array.from(mergedServicesCodes).map(code => {
        return mergedServices.find(
          mergedService => mergedService.service?.code === code
        );
      });

      return uniqueServices;
    }
  },
  mutations: {
    RESET(state) {
      state.loadingBooking = false;
      state.booking = null;
      state.loadingClientPayments = false;
      state.clientPayments = [];
      state.loadingGuests = false;
      state.guests = [];
      state.loadingAccommodationServices = false;
      state.accommodationServices = [];
    },
    SET_LOADING_BOOKING(state, payload) {
      state.loadingBooking = payload;
    },
    SET_BOOKING(state, payload) {
      state.booking = payload;
    },
    SET_DETAILS(state, payload) {
      if (!state.booking) return;
      state.booking.details = payload;
    },
    SET_STATS(state, payload) {
      if (!state.booking) return;
      state.booking.stats = payload;
    },
    SET_LOADING_CLIENT_PAYMENTS(state, payload) {
      state.loadingClientPayments = payload;
    },
    SET_CLIENT_PAYMENTS(state, payload) {
      state.clientPayments = payload;
    },
    ADD_CLIENT_PAYMENT(state, clientPayment) {
      state.clientPayments.push(clientPayment);
    },
    UPDATE_CLIENT_PAYMENT(state, clientPayment) {
      const clientPaymentIndex = state.clientPayments.findIndex(
        cp => cp.uuid === clientPayment.uuid
      );
      state.clientPayments.splice(clientPaymentIndex, 1, clientPayment);
    },
    REMOVE_CLIENT_PAYMENT(state, clientPaymentUuid) {
      const clientPaymentIndex = state.clientPayments.findIndex(
        cp => cp.uuid === clientPaymentUuid
      );
      state.clientPayments.splice(clientPaymentIndex, 1);
    },
    SET_LOADING_GUESTS(state, payload) {
      state.loadingGuests = payload;
    },
    SET_GUESTS(state, payload) {
      state.guests = payload;
    },
    ADD_GUEST(state, guest) {
      state.guests.push(guest);
    },
    UPDATE_GUEST(state, guest) {
      const guestIndex = state.guests.findIndex(g => g.uuid === guest.uuid);
      state.guests.splice(guestIndex, 1, guest);
    },
    REMOVE_GUEST(state, guestUuid) {
      const guestIndex = state.guests.findIndex(g => g.uuid === guestUuid);
      state.guests.splice(guestIndex, 1);
    },
    SET_REVIEW(state, payload) {
      state.booking.review = payload;
    },
    SET_LOADING_ACCOMMODATION_SERVICES(state, payload) {
      state.loadingAccommodationServices = payload;
    },
    SET_ACCOMMODATION_SERVICES(state, payload) {
      state.accommodationServices = payload;
    }
  },
  actions: {
    reset({ commit }) {
      commit("RESET");
    },
    async fetchBookingByLocalizator({ commit }, localizator) {
      try {
        commit("SET_LOADING_BOOKING", true);

        if (!localizator) {
          throw new Error("localizator is null or empty");
        }

        const searchResponse = await axios.get(
          `/bookings?localizator=${localizator}`
        );
        const foundBookings = searchResponse.data["hydra:member"];

        if (!foundBookings.length) {
          throw new Error(
            "the booking for the given localizator does not exist"
          );
        }

        const bookingUri = foundBookings[0]["@id"];
        const response = await axios.get(bookingUri);

        commit("SET_BOOKING", response.data);
        commit("SET_LOADING_BOOKING", false);

        return response.data;
      } catch (error) {
        // TODO: Log error in Sentry
        commit("SET_BOOKING", null);
        commit("SET_LOADING_BOOKING", false);
        throw new Error(`[fetchBookingByLocalizator] ${error.message}`);
      }
    },
    async updateBooking({ commit }, booking) {
      try {
        const response = await axios.patch(
          `/bookings/${booking.uuid}`,
          booking,
          {
            headers: {
              "Content-Type": "application/merge-patch+json"
            }
          }
        );

        if (response.status === 200) {
          commit("SET_BOOKING", response.data);
          commit("bookings/UPDATE_BOOKING", response.data, { root: true });
          return response.data;
        } else {
          throw new Error(
            `Update failed with an unexpected response status: ${response.status}.`
          );
        }
      } catch (error) {
        // TODO: log error in Sentry
        throw new Error(`[updateBooking] ${error.message}`);
      }
    },
    async updateDetails({ commit }, details) {
      try {
        const response = await axios.patch(
          `/booking_details/${details.uuid}`,
          details,
          {
            headers: {
              "Content-Type": "application/merge-patch+json"
            }
          }
        );

        if (response.status === 200) {
          commit("SET_DETAILS", response.data);
          return response.data;
        } else {
          throw new Error(
            `Update failed with an unexpected response status: ${response.status}.`
          );
        }
      } catch (error) {
        // TODO: log error in Sentry
        throw new Error(`[updateDetails] ${error.message}`);
      }
    },
    async updateStats({ commit }, stats) {
      try {
        const response = await axios.patch(
          `/booking_stats/${stats.uuid}`,
          stats,
          {
            headers: {
              "Content-Type": "application/merge-patch+json"
            }
          }
        );

        if (response.status === 200) {
          commit("SET_STATS", response.data);
          return response.data;
        } else {
          throw new Error(
            `Update failed with an unexpected response status: ${response.status}.`
          );
        }
      } catch (error) {
        // TODO: log error in Sentry
        throw new Error(`[updateStats] ${error.message}`);
      }
    },
    async fetchClientPayments({ commit }, bookingUuid) {
      try {
        if (!bookingUuid) {
          return null;
        }

        commit("SET_LOADING_CLIENT_PAYMENTS", true);
        const response = await axios.get(
          `/bookings/${bookingUuid}/client_payments?pagination=false`
        );
        const clientPayments = response.data["hydra:member"];
        commit("SET_CLIENT_PAYMENTS", clientPayments);
        return clientPayments;
      } catch (error) {
        // TODO: log the error with Sentry
        throw new Error(`[fetchClientPayments] ${error.message}`);
      } finally {
        commit("SET_LOADING_CLIENT_PAYMENTS", false);
      }
    },
    async fetchGuests({ commit }, bookingUuid) {
      try {
        if (!bookingUuid) {
          return null;
        }

        commit("SET_LOADING_GUESTS", true);
        const response = await axios.get(
          `/bookings/${bookingUuid}/guests?pagination=false`
        );
        const guests = response.data["hydra:member"];
        commit("SET_GUESTS", guests);
        return guests;
      } catch (error) {
        // TODO: log the error with Sentry
        throw new Error(`[fetchGuests] ${error.message}`);
      } finally {
        commit("SET_LOADING_GUESTS", false);
      }
    },
    async addGuest({ commit }, guest) {
      try {
        const response = await axios.post("/guests", guest);

        if (response.status === 201) {
          commit("ADD_GUEST", response.data);
          return response.data;
        } else {
          throw new Error(
            `Addition failed with an unexpected response status: ${response.status}.`
          );
        }
      } catch (error) {
        // TODO: log error in Sentry
        throw new Error(`[addGuest] ${error.message}`);
      }
    },
    async updateGuest({ commit }, guest) {
      try {
        const response = await axios.patch(`/guests/${guest.uuid}`, guest, {
          headers: {
            "Content-Type": "application/merge-patch+json"
          }
        });

        if (response.status === 200) {
          commit("UPDATE_GUEST", response.data);
          return response.data;
        } else {
          throw new Error(
            `Update failed with an unexpected response status: ${response.status}.`
          );
        }
      } catch (error) {
        // TODO: log error in Sentry
        throw new Error(`[updateGuest] ${error.message}`);
      }
    },
    async deleteGuest({ commit }, guestUuid) {
      try {
        const response = await axios.delete(`/guests/${guestUuid}`);

        if (response.status === 204) {
          commit("REMOVE_GUEST", guestUuid);
          return response;
        } else {
          throw new Error(
            `Deletion failed with an unexpected response status: ${response.status}.`
          );
        }
      } catch (error) {
        // TODO: log error in Sentry
        throw new Error(`[deleteGuest] ${error.message}`);
      }
    },
    async updateReview({ commit }, review) {
      try {
        const response = await axios.patch(`/reviews/${review.uuid}`, review, {
          headers: {
            "Content-Type": "application/merge-patch+json"
          }
        });

        if (response.status === 200) {
          commit("SET_REVIEW", response.data);
          return response.data;
        } else {
          throw new Error(
            `Update failed with an unexpected response status: ${response.status}.`
          );
        }
      } catch (error) {
        // TODO: log error in Sentry
        throw new Error(`[updateReview] ${error.message}`);
      }
    },
    async fetchAccommodationServices({ commit }, accommodationUuid) {
      try {
        if (!accommodationUuid) {
          throw new Error("The accommodation uuid is null or empty");
        }

        commit("SET_LOADING_ACCOMMODATION_SERVICES", true);
        const response = await axios.get(
          `/accommodations/${accommodationUuid}/services?pagination=false`
        );
        const accommodationServices = response.data["hydra:member"];
        commit("SET_ACCOMMODATION_SERVICES", accommodationServices);
        return accommodationServices;
      } catch (error) {
        // TODO: Log error in Sentry
        throw new Error(`[fetchAccommodationServices] ${error.message}`);
      } finally {
        commit("SET_LOADING_ACCOMMODATION_SERVICES", false);
      }
    }
  }
};
